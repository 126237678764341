import { Flex } from '@brightdrop/bd-ui';
import { cx } from '@emotion/css';
import { NavBarItem } from '@gm-commercial/navbar-model';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import ArrowDownIcon from '../../assets/icons/new/arrowDownIcon.svg?react';
import ArrowUpIcon from '../../assets/icons/new/arrowUpIcon.svg?react';
import useStyles from './NavDrawerItem.styles';

export type NavDrawerItemProps = NavBarItem & {
  isNavDrawerOpen?: boolean;
  currentPagePath?: string;
  typographyClass?: 'body_medium' | 'body_regular';
};

/**
 * Normalizes a given path using the current origin.
 * @param {string} path - The path to normalize.
 * @returns {string} - The normalized pathname.
 */
const normalizePath = (path: string) => {
  return new URL(path, window.location.origin).pathname || path;
};

const ContentNavDrawerItem = (item: NavDrawerItemProps): JSX.Element => {
  const { classes } = useStyles();
  const history = useHistory();
  const [isItemOpen, setIsItemOpen] = useState(true);
  const [isCollapsedButtonHovered, setIsCollapsedButtonHovered] =
    useState<boolean>(false);
  const toggleContent = useCallback(() => {
    setIsItemOpen((previousValue) => !previousValue);
  }, []);
  const navDrawerItemLabel = useRef<HTMLDivElement>(null);

  /**
   * Normalized path of the current page, if defined.
   * This variable is used throughout the component to determine if the current page matches an item's path
   * to drive various styles and behavior.
   * @type {string | undefined}
   */
  const normalizedCurrentPagePath = item.currentPagePath
    ? normalizePath(item.currentPagePath)
    : undefined;

  /**
   * Determines if any child path matches the normalized current page path.
   * If so, this ContentNavDrawerItem is considered an active parent container,
   * which affects styling.
   * @type {boolean}
   */
  const isActiveParent =
    item?.children?.some(
      ({ path: childPath }: NavBarItem) =>
        childPath && normalizePath(childPath) === normalizedCurrentPagePath
    ) ?? false;

  /**
   * Checks if the parent path matches the normalized current page path.
   * This is used to determine if we need to open the dropdown when the parent list item is clicked.
   * Otherwise, only the arrow should toggle the open/close state.
   * @type {boolean}
   */
  const doesParentPathMatchCurrentPagePath = item.path
    ? normalizePath(item.path) === normalizedCurrentPagePath
    : false;

  /**
   * Determines if the drawer is open and the item is open.
   * @type {boolean}
   */
  const isEverythingOpen = item.isNavDrawerOpen && isItemOpen;

  /**
   * Determines if the drawer is closed and the item is hovered.
   * Used to drive collapsed state hover behaviors.
   * @type {boolean}
   */
  const isDrawerClosedAndItemHovered =
    !item.isNavDrawerOpen && isCollapsedButtonHovered;

  /**
   * Determines if the children should be shown based on whether everything is open
   * or if the drawer is closed and the item is hovered.
   * @type {boolean}
   */
  const shouldShowChildren = isEverythingOpen || isDrawerClosedAndItemHovered;

  const handleParentListItemClick = (e: React.MouseEvent<HTMLLIElement>) => {
    if (
      e.target === navDrawerItemLabel.current &&
      !doesParentPathMatchCurrentPagePath
    ) {
      if (!isItemOpen) {
        setIsItemOpen(true);
      }

      if (item.path) {
        history.push(item.path);
      }
    }
  };
  const handleParentListItemKeyDown = (
    e: React.KeyboardEvent<HTMLLIElement>
  ) => {
    const { key } = e;

    if (key !== 'Enter') return;

    if (!item.isNavDrawerOpen) {
      if (!isItemOpen && !doesParentPathMatchCurrentPagePath) {
        setIsItemOpen(true);
      }
      item.path && history.push(item.path);
    }
  };

  return (
    <ListItem
      data-testid={item.testId}
      className={cx(
        classes.listItem,
        'contentItem',
        classes.nestingContainer,
        item.isNavDrawerOpen && 'isNavDrawerOpen'
      )}
      onMouseEnter={() => {
        !item.isNavDrawerOpen && setIsCollapsedButtonHovered(true);
      }}
      onMouseLeave={() => {
        !item.isNavDrawerOpen && setIsCollapsedButtonHovered(false);
      }}
      onClick={handleParentListItemClick}
      onKeyDown={handleParentListItemKeyDown}
      tabIndex={!item.isNavDrawerOpen ? 0 : -1}
    >
      <Flex
        className={cx(
          'nestingContainerTitle',
          isActiveParent && classes.activeItem
        )}
      >
        <Flex className={'nestingContainerTitleIconText'}>
          {item.icon && (
            <ListItemIcon
              className={classes.itemIcon}
              data-testid={`${item.testId}-icon`}
            >
              {item.icon}
            </ListItemIcon>
          )}
          {item.isNavDrawerOpen && (
            <>
              <Typography
                variant={'body_medium'}
                className={cx(classes.itemText, 'contentItem')}
                ref={navDrawerItemLabel}
              >
                {item.label}
              </Typography>
              <Button
                className={classes.toggleIcon}
                tabIndex={0}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  toggleContent();
                }}
                data-testid={`${item.testId}-toggle-btn`}
              >
                {item.isNavDrawerOpen &&
                  (isItemOpen ? (
                    <ArrowUpIcon className="noFill" />
                  ) : (
                    <ArrowDownIcon className="noFill" />
                  ))}
              </Button>
            </>
          )}
        </Flex>
      </Flex>
      {shouldShowChildren && (
        <Box
          className={cx(
            classes.contentNavDrawerItemContainer,
            isCollapsedButtonHovered && 'collapsedHover'
          )}
        >
          <List>
            {item.children?.map((child) => (
              <NavDrawerItem
                typographyClass="body_regular"
                key={`child-${child.testId}`}
                {...child}
                isNavDrawerOpen={
                  item.isNavDrawerOpen || isCollapsedButtonHovered
                }
              />
            ))}
          </List>
        </Box>
      )}
    </ListItem>
  );
};

const LinkNavDrawerItem = (item: NavDrawerItemProps): JSX.Element => {
  const { classes } = useStyles();
  const onNavigate = useCallback((callback?: () => void) => {
    if (callback) {
      callback();
    }
  }, []);
  const handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // Prevent the event from propagating to parent drawer element and triggering click actions.
    e.stopPropagation();
    onNavigate(item.callback);
  };
  return (
    <ListItem
      data-testid={item.testId}
      className={classes.listItem}
      component={NavLink}
      to={item.path || ''}
      activeClassName={cx('Mui-selected Mui-disabled', classes.activeItem)}
      onClick={handleItemClick}
      exact
    >
      {item.icon && (
        <ListItemIcon
          className={classes.itemIcon}
          data-testid={`${item.testId}-icon`}
        >
          {item.icon}
        </ListItemIcon>
      )}
      {item.isNavDrawerOpen && (
        <Typography variant={item.typographyClass} className={classes.itemText}>
          {item.label}
        </Typography>
      )}
    </ListItem>
  );
};

const NavDrawerItem = (item: NavDrawerItemProps): JSX.Element => {
  const { pathname } = useLocation();

  if (!item.typographyClass) {
    item = { typographyClass: 'body_medium', ...item };
  }

  return (
    <>
      {item.children?.length ? (
        <ContentNavDrawerItem {...item} currentPagePath={pathname} />
      ) : (
        <LinkNavDrawerItem {...item} />
      )}
    </>
  );
};

export default NavDrawerItem;
