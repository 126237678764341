import { RootState } from '~/app/rootReducer';
import {
  API_VERSION_DEFAULTS,
  ASSETS_API_BASE_CONFIG,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import {
  SearchConfigType,
  SessionConfigType,
} from '~/common/constants/common.constant';
import { ApiResponse } from '~/common/models/apis/apiResponse.model';
import {
  AddAssetFormFields,
  ApiAsset,
  Asset,
  AssetComplianceDetail,
  AssetEvent,
  AssetEventAction,
  AssetModel,
  AssetProtectedFormFields,
  AssetServiceStatus,
} from '~/common/models/asset.model';
import {
  ASSET_REPORT_FIELD_NAMES,
  AssetReportItem,
} from '~/common/models/asset-report.model';
import {
  AssetRawLocation,
  FormPayload,
  PagedResult,
  PagedResultWithErrors,
} from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import { CHARGING_SCHEDULE_FLAG_NAME } from '~/common/models/featureFlags.model';
import {
  addAcceptLanguageHeader,
  hasApiResult,
  resultIsArray,
} from '~/common/utils/apis/api.utils';
import {
  makeDeletePayloadCreator,
  makeGetPayloadCreator,
  makePostPayloadCreator,
  makePutPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import {
  AssetHistoryParams,
  AssetListSession,
  AssetPaginatedListParams,
  AssetReportConfigType,
  AssetRouteParams,
  UpdateAssetFormPayload,
} from '../assetsSlice';
import { AssetListParams } from '../form/utils/assetForm.utils';
import {
  checkDiagnosticsError,
  mapApiAssetComplianceToAssetComplianceDetail,
  mapAssetApiResponseToAsset,
  mapAssetFormToApiRequest,
  mapEventApiResponseToEvent,
  mapGeoJSONApiResponseToLocation,
} from '../mappers/asset.mappers';
import {
  ApiBatteryReadinessItem,
  ApiReadinessItem,
  ApiTiresReadinessItem,
  mapApiAssetBatteryReadinessToReportItem,
  mapApiAssetReadinessToReportItem,
  mapApiAssetTireReadinessToReportItem,
} from '../report/mappers/asset-report.mappers';
import {
  buildAssetComplianceQuery,
  buildAssetQuery,
  buildAssetSort,
} from '../utils/assets.utils';

const BASE_CONFIG = ASSETS_API_BASE_CONFIG;

const BATTERY_READINESS_CONFIG = {
  ...DEFAULT_API_CONFIG,
  axiosConfig: {
    ...DEFAULT_API_CONFIG.axiosConfig,
  },
};

// Toogle for using /location/geojson vs /location for asset location service
const GEOJSON_TOGGLE = true;

enum AssetsActionType {
  GET_ASSETS = 'assets/fetchAssets',
  GET_VEHICLE_NOTIFICATION_ASSETS = 'assets/getVehicleNotificationAssets',
  GET_READINESS_REPORT = 'assets/getReadinessReport',
  GET_TIRES_REPORT = 'assets/getTiresReport',
  GET_BATTERY_REPORT = 'assets/getBatteryReport',
  GET_ASSETS_SEARCH = 'assets/fetchAssetsSearch',
  GET_ASSETS_COMPLIANCE = 'assets/getAssetsCompliance',
  GET_ASSET_DETAILS = 'assets/fetchDetails',
  GET_ASSET_HISTORY = 'assets/fetchHistory',
  GET_ASSET_LOCATION = 'asset/fetchLocation',
  ADD_ASSET = 'assets/addNewAsset',
  UPDATE_ASSET = 'assets/updateAsset',
  LOCK_ASSET = 'assets/lockAsset',
  UPDATE_AVAILABILITY = 'assets/updateServiceAvailability',
  REPROVISION_ASSET = 'assets/reProvisionAsset',
  /* TODO: following actions should be reviewed for removal */
  DELETE_ASSET = 'assets/deleteAsset',
  GET_NEXT_EP_SERIAL = 'assets/getNextEPalletSerial',
  GET_TRACKER_SERIALS = 'assets/getTrackerSerials',
  GET_EP_VERSIONS = 'assets/getEPalletVersions',
}

const getAssets = makeThunk(
  AssetsActionType.GET_ASSETS,
  makeGetPayloadCreator<ApiResponse<Asset[]>, AssetListParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: (routeParams) => {
      return {
        requestParams: {
          organizationId: routeParams.organizationsId || '',
          hubId: routeParams.hubsId || '',
          fleetId: routeParams.fleetsId || '',
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>, state) => {
      if (!!response && resultIsArray<Partial<ApiAsset>>(response)) {
        const errors = new Array<BDError>();
        const result = {
          ...response,
          result: response.result?.length
            ? response.result.reduce((mappedAssets, apiAsset) => {
                try {
                  mappedAssets.push(mapAssetApiResponseToAsset(apiAsset));
                } catch (e) {
                  // handle mapping errors without bombing entire list response
                  const error = {
                    name: 'Get Assets Mapping Error',
                    type: BDAppErrorType.VALIDATION,
                    message:
                      e instanceof Error
                        ? e.message
                        : 'Failed to map asset API response',
                    data: apiAsset,
                  };
                  errors.push(BDError.asJson(error));
                }
                return mappedAssets;
              }, new Array<Asset>())
            : [],
        };
        if (errors.length) {
          result.errors = errors;
        }

        return result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError('Unexpected asset list response', { data: response });
    },
  })
);

const getVehicleNotificationAssets = makeThunk(
  AssetsActionType.GET_VEHICLE_NOTIFICATION_ASSETS,
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<AssetReportItem>>,
    AssetPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/views/readinessReport`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ page, rowsPerPage: size, sessionId, ...params }, state) => {
      const sessionConfig = getAssetListQuerySessionConfig({
        sessionId,
        state,
      });

      const query = buildAssetQuery(
        sessionConfig,
        params,
        ASSET_REPORT_FIELD_NAMES
      );
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          page,
          size,
          query,
          sort,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<PagedResult<Partial<ApiReadinessItem>>>(response)
      ) {
        const errors = new Array<BDError>();
        const { items, total_items } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            items: items?.length
              ? items.reduce((mappedAssets, apiAsset) => {
                  try {
                    mappedAssets.push(
                      mapApiAssetReadinessToReportItem(apiAsset)
                    );
                  } catch (e) {
                    // handle mapping errors without bombing entire list response
                    errors.push({
                      name: 'Get Vehicle Notification Assets error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map asset readiness API response',
                      data: apiAsset,
                    });
                  }
                  return mappedAssets;
                }, new Array<AssetReportItem>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError('Unexpected asset report response', { data: response });
    },
  })
);

const getAssetListQuerySessionConfig = ({
  state,
  sessionId,
}: {
  sessionId: string;
  state: RootState;
}): Partial<AssetListSession> | undefined => {
  const sessionConfig =
    state.assets.sessionConfigs?.[SessionConfigType.LIST_VIEW]?.[sessionId];
  return sessionConfig;
};

const getReadinessReport = makeThunk(
  AssetsActionType.GET_READINESS_REPORT,
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<AssetReportItem>>,
    AssetPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/views/readinessReport`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ page, rowsPerPage: size, sessionId, ...params }, state) => {
      const sessionConfig = getAssetListQuerySessionConfig({
        sessionId,
        state,
      });

      const query = buildAssetQuery(
        sessionConfig,
        params,
        ASSET_REPORT_FIELD_NAMES
      );
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          page,
          size,
          query,
          sort,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<PagedResult<Partial<ApiReadinessItem>>>(response)
      ) {
        const errors = new Array<BDError>();
        const { items, total_items } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            items: items?.length
              ? items.reduce((mappedAssets, apiAsset) => {
                  try {
                    mappedAssets.push(
                      mapApiAssetReadinessToReportItem(apiAsset)
                    );
                  } catch (e) {
                    // handle mapping errors without bombing entire list response
                    errors.push({
                      name: 'Get Readiness Report error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map asset readiness API response',
                      data: apiAsset,
                    });
                  }
                  return mappedAssets;
                }, new Array<AssetReportItem>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError('Unexpected asset report response', { data: response });
    },
  })
);

const getTiresReport = makeThunk(
  AssetsActionType.GET_TIRES_REPORT,
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<AssetReportItem>>,
    AssetPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/views/tireReadinessReport`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ page, rowsPerPage: size, sessionId, ...params }, state) => {
      const sessionConfig = getAssetListQuerySessionConfig({
        sessionId,
        state,
      });

      const query = buildAssetQuery(
        sessionConfig,
        params,
        ASSET_REPORT_FIELD_NAMES
      );
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          page,
          size,
          query,
          sort,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<PagedResult<Partial<ApiTiresReadinessItem>>>(response)
      ) {
        const errors = new Array<BDError>();
        const { items, total_items } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            items: items?.length
              ? items.reduce((mappedAssets, apiAsset) => {
                  try {
                    mappedAssets.push(
                      mapApiAssetTireReadinessToReportItem(apiAsset)
                    );
                  } catch (e) {
                    // handle mapping errors without bombing entire list response
                    errors.push({
                      name: 'Get Tires Report error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map asset tire readiness API response',
                      data: apiAsset,
                    });
                  }
                  return mappedAssets;
                }, new Array<AssetReportItem>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError('Unexpected asset tire report response', {
        data: response,
      });
    },
  })
);

const getBatteryReport = makeThunk(
  AssetsActionType.GET_BATTERY_REPORT,
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<AssetReportItem>>,
    AssetPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/views/batteryReadinessReport`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(
        BATTERY_READINESS_CONFIG,
        state.profile.currentLocale
      ),
    argAdapter: ({ page, rowsPerPage: size, sessionId, ...params }, state) => {
      const sessionConfig = getAssetListQuerySessionConfig({
        sessionId,
        state,
      });

      const query = buildAssetQuery(
        sessionConfig,
        params,
        ASSET_REPORT_FIELD_NAMES,
        true
      );
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          page,
          size,
          query,
          sort,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<PagedResult<Partial<ApiBatteryReadinessItem>>>(response)
      ) {
        const errors = new Array<BDError>();
        const { items, total_items } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            items: items?.length
              ? items.reduce((mappedAssets, apiAsset) => {
                  try {
                    mappedAssets.push(
                      mapApiAssetBatteryReadinessToReportItem(apiAsset)
                    );
                  } catch (e) {
                    errors.push({
                      name: 'Get Battery Report error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map asset battery readiness API response',
                      data: apiAsset,
                    });
                  }
                  return mappedAssets;
                }, new Array<AssetReportItem>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      throw new BDError('Unexpected asset battery report response', {
        data: response,
      });
    },
  })
);

const getAssetsCompliance = makeThunk(
  AssetsActionType.GET_ASSETS_COMPLIANCE,
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<AssetComplianceDetail>>,
    AssetPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.assetComplianceAPI}/views/complianceReport`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(DEFAULT_API_CONFIG, state.profile.currentLocale),
    argAdapter: ({ page, rowsPerPage: size, sessionId, ...params }, state) => {
      const sessionConfig =
        state.assets.sessionConfigs?.[AssetReportConfigType.COMPLIANCE_VIEW]?.[
          sessionId
        ];
      const isChargeStatusFlagEnabled =
        !!state.featureFlags.entities[CHARGING_SCHEDULE_FLAG_NAME]?.value;
      const query = buildAssetComplianceQuery(
        sessionConfig,
        params,
        isChargeStatusFlagEnabled
      );
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          page,
          size,
          query,
          sort,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<PagedResult<Partial<AssetComplianceDetail>>>(response)
      ) {
        const errors = new Array<BDError>();
        const { items, total_items } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            items: items?.length
              ? items.reduce((mappedAssets, apiAsset) => {
                  try {
                    mappedAssets.push(
                      mapApiAssetComplianceToAssetComplianceDetail(apiAsset)
                    );
                  } catch (e) {
                    // handle mapping errors without bombing entire list response
                    errors.push({
                      name: 'Get Assets Compliance error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map Asset compliance API response',
                      data: apiAsset,
                    });
                  }
                  return mappedAssets;
                }, new Array<AssetComplianceDetail>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError('Unexpected asset compliance response', {
        data: response,
      });
    },
  })
);

const searchAssets = makeThunk(
  AssetsActionType.GET_ASSETS_SEARCH,
  makePostPayloadCreator<
    ApiResponse<PagedResult<Asset>>,
    AssetPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/searches`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: (
      { page, rowsPerPage: size, sessionId, ...contextFilter },
      state
    ) => {
      const sessionConfig =
        state.assets.sessionConfigs?.[SearchConfigType.SEARCH_VIEW]?.[
          sessionId
        ];
      const query = buildAssetQuery(
        sessionConfig,
        contextFilter,
        ASSET_REPORT_FIELD_NAMES
      );
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          page,
          size,
          query,
          sort,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>, state) => {
      if (
        !!response &&
        hasApiResult<PagedResult<Partial<ApiAsset>>>(response)
      ) {
        const errors = new Array<BDError>();
        const { items, total_items } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            items: items?.length
              ? items.reduce((mappedAssets, apiAsset) => {
                  try {
                    mappedAssets.push(mapAssetApiResponseToAsset(apiAsset));
                  } catch (e) {
                    // handle mapping errors without bombing entire list response
                    errors.push({
                      name: 'Search Assets error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'failed to map Asset API response',
                      data: apiAsset,
                    });
                  }
                  return mappedAssets;
                }, new Array<Asset>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError('Unexpected asset list response', { data: response });
    },
  })
);

const getAssetDetails = makeThunk(
  AssetsActionType.GET_ASSET_DETAILS,
  makeGetPayloadCreator<ApiResponse<Asset>, Required<AssetRouteParams>>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/views/enhancements/:assetsId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(DEFAULT_API_CONFIG, state.profile.currentLocale),
    argAdapter: ({ assetsId }) => ({ requestParams: { assetsId } }),
    responseAdapter: (response: unknown, state): ApiResponse<Asset> => {
      if (!!response && hasApiResult<ApiAsset>(response)) {
        const errors = new Array<BDError>();
        const apiAsset = response.result;
        try {
          // Map API response to UI Asset
          const mappedAsset = mapAssetApiResponseToAsset(apiAsset);

          const diagnosticsError = checkDiagnosticsError(mappedAsset);
          if (diagnosticsError) {
            errors.push({
              name: 'Asset Diagnostic Error',
              type: BDAppErrorType.VALIDATION,
              message: diagnosticsError,
            });
          }
          return {
            ...response,
            result: mappedAsset,
            errors,
          };
        } catch (e) {
          // handle mapping errors without bombing entire list response
          errors.push({
            name: 'Asset Details API Error',
            type: BDAppErrorType.API,
            message:
              e instanceof Error
                ? e.message
                : 'Failed to map asset API response',
            data: apiAsset,
          });

          return {
            ...response,
            errors: [
              BDError.asJson(
                e instanceof Error
                  ? e
                  : new Error('Failed to parse asset detail response')
              ),
            ],
          } as ApiResponse<Asset>;
        }
      }
      throw new BDError('Unexpected asset detail response', { data: response });
    },
  })
);

const getAssetHistory = makeThunk(
  AssetsActionType.GET_ASSET_HISTORY,
  makeGetPayloadCreator<ApiResponse<AssetEvent[]>, AssetHistoryParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.assetHistoryAPI}/:assetsId/history`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({
      assetsId,
      startDate,
      endDate,
      actions = [
        AssetEventAction.POWER_ON,
        AssetEventAction.POWER_OFF,
        AssetEventAction.LOCK,
        AssetEventAction.DOOR,
      ],
    }) => ({
      requestParams: {
        assetsId,
        startDate,
        endDate,
        actions: actions.join(','),
      },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: response?.result?.items?.length
        ? mapEventApiResponseToEvent(response?.result?.items)
        : [],
    }),
  })
);

const getAssetLocation = makeThunk(
  AssetsActionType.GET_ASSET_LOCATION,
  makeGetPayloadCreator<ApiResponse<AssetRawLocation[]>, AssetHistoryParams>({
    url: (params) =>
      params.locationUpgradeFlagActive
        ? `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.assetLocationsAPI}/:assetsId/location/geojson`
        : `${globalThis.appConfig.apiBaseUrl}/assets/:assetsId/location${
            GEOJSON_TOGGLE ? '/geojson' : ''
          }`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ assetsId, startDate, endDate }) => ({
      requestParams: {
        assetsId,
        startDate,
        endDate,
      },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: response?.result?.geojson?.geometry?.coordinates?.length
        ? mapGeoJSONApiResponseToLocation(response?.result.geojson)
        : {},
    }),
  })
);

const addAsset = makeThunk(
  AssetsActionType.ADD_ASSET,
  makePostPayloadCreator<ApiResponse<Asset>, AddAssetFormFields>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}`,
    argAdapter: (assetForm) => ({ requestBody: assetForm }),
  })
);

const reProvisionAsset = makeThunk(
  AssetsActionType.UPDATE_ASSET,
  makePutPayloadCreator<ApiResponse<void>, { assetId: string }>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/admin/telemetry/:assetId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ assetId }) => ({
      requestParams: { assetId },
    }),
  })
);
const saveAssetProtectedFields = makeThunk(
  AssetsActionType.UPDATE_ASSET,
  makePostPayloadCreator<
    ApiResponse<void>,
    AssetProtectedFormFields & { assetId: string }
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/admin/:assetId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ assetId, ...fields }) => ({
      requestParams: { assetId },
      requestBody: fields,
    }),
  })
);

const updateAsset = makeThunk(
  AssetsActionType.UPDATE_ASSET,
  makePutPayloadCreator<ApiResponse<Asset>, UpdateAssetFormPayload>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/:assetId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({
      requestParams: { assetId: payload.params.assetsId },
      requestBody: mapAssetFormToApiRequest(payload),
    }),
  })
);

const lockAsset = makeThunk(
  AssetsActionType.LOCK_ASSET,
  makePutPayloadCreator<
    ApiResponse,
    FormPayload<{ value: boolean }, keyof AssetRouteParams>
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/:assetId/status/lock`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ formFields, params }) => ({
      requestParams: { assetId: params.assetsId },
      requestBody: formFields,
    }),
  })
);

const updateServiceAvailability = makeThunk(
  AssetsActionType.UPDATE_AVAILABILITY,
  makePostPayloadCreator<
    ApiResponse<Asset>,
    {
      assetId: string;
      currentState: AssetServiceStatus;
      desiredState: AssetServiceStatus;
    }
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/:assetId/states/availability`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ assetId, currentState, desiredState }) => ({
      requestParams: { assetId },
      requestBody: {
        current: currentState,
        desired: desiredState,
      },
    }),
  })
);

const deleteAsset = makeThunk(
  AssetsActionType.DELETE_ASSET,
  makeDeletePayloadCreator<ApiResponse, string>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/:assetId`,
    argAdapter: (assetId) => ({
      requestParams: { assetId },
    }),
  })
);

const getNextEPalletSerial = makeThunk(
  AssetsActionType.GET_NEXT_EP_SERIAL,
  makeGetPayloadCreator<ApiResponse<string>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/nextEPalletSerial`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
  })
);

const getTrackerSerials = makeThunk(
  AssetsActionType.GET_TRACKER_SERIALS,
  makeGetPayloadCreator<ApiResponse<string[]>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/trackerSerials`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
  })
);

const getEPalletVersions = makeThunk(
  AssetsActionType.GET_EP_VERSIONS,
  makeGetPayloadCreator<ApiResponse<AssetModel[]>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/ePalletVersions`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
  })
);

export const ASSETS_ACTIONS = {
  getAssets,
  getReadinessReport,
  getTiresReport,
  getBatteryReport,
  getAssetsCompliance,
  getVehicleNotificationAssets,
  searchAssets,
  getAssetDetails,
  getAssetHistory,
  getAssetLocation,
  addAsset,
  updateAsset,
  lockAsset,
  updateServiceAvailability,
  deleteAsset,
  getNextEPalletSerial,
  getTrackerSerials,
  getEPalletVersions,
  reProvisionAsset,
  saveAssetProtectedFields,
};
