import { NavBarConfig } from '@gm-commercial/navbar-model';
import { Profile } from '@gm-commercial/profile-model';

import {
  FeatureFlagValueType,
  MRT_FLAG_NAME,
} from '~/common/models/featureFlags.model';
import {
  FLEET_HOME,
  FLEET_VEHICLE_LIST,
  FLEET_VEHICLE_ORDERS_LIST,
  MRT_FLEET_VEHICLE_LIST,
} from '~/common/models/pages/fleetPages.model';
import { makeRoutePath } from '~/common/utils/route/route.utils';

import HomeIcon from '../../../assets/icons/homeIcon.svg?react';
import CarIcon from '../../../assets/icons/new/carIcon.svg?react';
import { CS_NAVBAR_MESSAGES } from '../CsNavDrawer.messages';

export const getFleetNavBarConfig = ({
  messages,
  profileParams,
  findFlagScope,
}: {
  messages: typeof CS_NAVBAR_MESSAGES.fleetMenu;
  profileParams: Profile['permissionsContextIds'];
  findFlagScope?: (flagName: string) => FeatureFlagValueType | undefined;
}): NavBarConfig => {
  const context = {
    fleetsId: profileParams?.fleetsId || undefined,
    hubsId: profileParams?.hubsId || undefined,
    organizationsId: profileParams?.organizationsId || undefined,
  };

  // remove once MRT integration is complete
  const isMRTEnabled = findFlagScope ? findFlagScope(MRT_FLAG_NAME) : false;
  return isMRTEnabled
    ? {
        homePath: '/',
        open: true,
        toggleable: true,
        items: [
          {
            label: messages['common:home'],
            icon: <HomeIcon className="noFill" />,
            testId: 'fleet-home',
            path: makeRoutePath(FLEET_HOME, context, true, true),
          },
          {
            label: messages['common:vehicles'],
            icon: <CarIcon className="noFill" />,
            testId: 'fleet-vehicles',
            path: makeRoutePath(FLEET_VEHICLE_LIST, context, true, true),
            children: [
              {
                label: messages['common:list'],
                testId: 'fleet-vehicle-list',
                path: makeRoutePath(FLEET_VEHICLE_LIST, context, true, true),
              },
              {
                label: messages['common:mrt:list'],
                testId: 'mrt-fleet-vehicle-list',
                path: makeRoutePath(
                  MRT_FLEET_VEHICLE_LIST,
                  context,
                  true,
                  true
                ),
              },
              {
                label: messages['order:tableTitle'],
                testId: 'fleet-vehicle-orders-list',
                path: makeRoutePath(
                  FLEET_VEHICLE_ORDERS_LIST,
                  context,
                  true,
                  true
                ),
              },
            ],
          },
        ],
      }
    : {
        homePath: '/',
        open: true,
        toggleable: true,
        items: [
          {
            label: messages['common:home'],
            icon: <HomeIcon className="noFill" />,
            testId: 'fleet-home',
            path: makeRoutePath(FLEET_HOME, context, true, true),
          },
          {
            label: messages['common:vehicles'],
            icon: <CarIcon className="noFill" />,
            testId: 'fleet-vehicles',
            path: makeRoutePath(FLEET_VEHICLE_LIST, context, true, true),
            children: [
              {
                label: messages['common:list'],
                testId: 'fleet-vehicle-list',
                path: makeRoutePath(FLEET_VEHICLE_LIST, context, true, true),
              },
              {
                label: messages['order:tableTitle'],
                testId: 'fleet-vehicle-orders-list',
                path: makeRoutePath(
                  FLEET_VEHICLE_ORDERS_LIST,
                  context,
                  true,
                  true
                ),
              },
            ],
          },
        ],
      };
};
