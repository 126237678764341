import {
  AlertPermission,
  AssetPermission,
  FleetPermission,
  HubPermission,
  InsightPermission,
  InspectionPermission,
  JobPermission,
  OrganizationPermission,
  ProfilePermission,
  Role,
  VehicleOrderPermission,
  WorkPlanPermission,
} from '@gm-commercial/profile-model';
import { lazy } from 'react';

import { UTILIZATION_FLAG_NAME } from '../../features/utilization/utilization.constant';
import {
  DISPATCH_MANAGEMENT_MVP_FLAG_NAME,
  DVIC_FLAG_NAME,
  INSIGHTS_FLAG_NAME,
  MRT_FLAG_NAME,
  SIMULATION_FLAG_NAME,
  TRIP_LOGGER_FLAG_NAME,
} from '../models/featureFlags.model';
import { NOTIFICATIONS_REQUIRED_ROLES } from '../models/notification.model';
import {
  ACCOUNT_PREFERENCES_LANGUAGE_REGION_PATH,
  ACCOUNT_PROFILE_PERSONAL_PATH,
} from '../models/pages/accountPages.model';
import {
  FLEET_HOME,
  FLEET_NOTIFICATIONS,
  FLEET_VEHICLE_LIST,
  FLEET_VEHICLE_LIST_DETAILS,
  FLEET_VEHICLE_ORDERS_DETAILS,
  FLEET_VEHICLE_ORDERS_LIST,
  MRT_FLEET_VEHICLE_LIST,
} from '../models/pages/fleetPages.model';
import {
  SETTINGS_NOTIFICATIONS_ACCOUNT,
  SETTINGS_NOTIFICATIONS_USERS,
  SETTINGS_NOTIFICATIONS_VEHICLES,
} from '../models/pages/settingsPages.model';
import { AppRoutePaths, RouteConfig, RouteName } from '../models/route.model';

export interface RouteParams {
  organizationsId?: string;
  hubsId?: string;
  fleetsId?: string;
  assetsId?: string;
  usersId?: string;
  reportId?: string;
  assetType?: string;
  plansId?: string;
  inspectionsId?: string;
  viewType?: string;
  workPlanId?: string;
  ordersId?: string;
}

const UserList = lazy(() => import('../../features/users/list/UserList'));
// Comment out the entry above and uncomment the entry below to view the MRT component on the User List page.
// const UserList = lazy(
//   () => import('../../features/users/list/alternatives/UserListView')
// );
const UserDetails = lazy(
  () => import('../../features/users/details/UserDetails')
);
const MapView = lazy(() => import('../../features/map/MapView'));
const HubList = lazy(() => import('../../features/hubs/list/HubList'));
const OrderList = lazy(
  () => import('../../features/orders/list/VehicleOrderListView')
);
const HubDetails = lazy(() => import('../../features/hubs/details/HubDetails'));
const FleetList = lazy(() => import('../../features/fleets/list/FleetList'));
const FleetDetails = lazy(
  () => import('../../features/fleets/details/FleetDetails')
);
const AssetDetails = lazy(
  () => import('../../features/assets/details/AssetDetails')
);
const CSPlatformAssetDetails = lazy(
  () => import('../../features/assets/details/AssetDetailsNew')
);
const CSVehicleOrderDetails = lazy(
  () => import('../../features/orders/details/VehicleOrderDetailsView')
);

const UserTermsConditions = lazy(
  () => import('../../features/users/termsConditions/UserTermsConditions')
);
const OrganizationTermsConditions = lazy(
  () =>
    import(
      '../../features/organizations/termsConditions/OrganizationTermsConditions'
    )
);
const DashboardView = lazy(
  () => import('../../features/dashboard/DashboardView')
);
const DispatchManagementSpace = lazy(
  () => import('../../features/dispatch/DispatchManagementSpace')
);
const OrganizationList = lazy(
  () => import('../../features/organizations/list/OrganizationList')
);
const OrganizationDetails = lazy(
  () => import('../../features/organizations/details/OrganizationDetails')
);

const DailyUtilizationSummary = lazy(
  () => import('../../features/utilization/summary/DailyUtilizationSummary')
);

const SimulationListView = lazy(
  () => import('../../features/simulation/SimulationListView')
);
const AssetListReportView = lazy(
  () => import('../../features/assets/report/AssetListReportView')
);
const MRTAssetListReportView = lazy(
  () => import('../../features/assets/report/MRT/AssetListReportView')
);

const PrivacyStatement = lazy(
  () => import('../../features/privacyStatement/Privacy')
);
const UserTerms = lazy(() => import('../../features/termsCondition/UserTerms'));
const EditFleetConfiguration = lazy(
  () => import('../../features/fleets/details/EditFleetConfiguration')
);
const InsightList = lazy(
  () => import('../../features/insights/list/InsightList')
);
const InsightDetails = lazy(
  () => import('../../features/insights/details/InsightDetails')
);
const AssetInspectionsListView = lazy(
  () => import('../../features/inspections/list/AssetInspectionsListView')
);
const AssetInspectionsDetailsView = lazy(
  () => import('../../features/inspections/details/AssetInspectionsDetailsView')
);
const WorkPlanDetailsSpace = lazy(
  () => import('../../features/dispatch/WorkPlanDetailsSpace')
);
const TripLoggerView = lazy(
  () => import('../../features/tripLogger/TripLoggerView')
);
const ConnectedCamerasView = lazy(
  () => import('../../features/connectedCameras/ConnectedCamerasView')
);
const CSPlatformDashboardView = lazy(
  () => import('../../features/dashboard/csPlatform/CSPlatformDashboardView')
);
const CSPlatformSettingsView = lazy(
  () => import('../../features/settings/CSPlatformSettingsView')
);
const CSPlatformSettingsNotificationsUsersView = lazy(
  () =>
    import('../../features/settings/CSPlatformSettingsNotificationsUsersView')
);
const CSPlatformSettingsNotificationsAccountsView = lazy(
  () =>
    import(
      '../../features/settings/CSPlatformSettingsNotificationsAccountsView'
    )
);

const CSPlatformAccountPreferencesView = lazy(
  () =>
    import(
      '../../features/account/preferences/CSPlatformAccountPreferencesView'
    )
);
const CSPlatformAccountProfileView = lazy(
  () => import('../../features/account/profile/CSPlatformAccountProfileView')
);
const CSPlatformNotificationListWrapper = lazy(
  () =>
    import('../../common/components/list/CSPlatformNotificationListViewWrapper')
);

const DASHBOARD_ROUTE: RouteConfig = {
  name: RouteName.DASHBOARD,
  path: [
    '/organizations/:organizationsId/dashboard',
    '/organizations/:organizationsId/hubs/:hubsId/dashboard',
    '/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId/dashboard',
  ],
  authorizedPerms: [AssetPermission.READ, OrganizationPermission.READ],
  authorizedRoles: [
    Role.SYSTEM_MANAGER,
    Role.ORG_MANAGER,
    Role.HUB_MANAGER,
    Role.FLEET_MANAGER,
  ],
  secure: true,
  component: DashboardView,
};

export const APP_ROUTES_CONFIG_MAPPING = {
  [RouteName.USER_LIST]: {
    name: RouteName.USER_LIST,
    path: [
      '/users',
      '/organizations/:organizationsId/users',
      '/organizations/:organizationsId/fleets/:fleetsId/users',
      '/organizations/:organizationsId/hubs/:hubsId/users',
      '/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId/users',
    ],
    authorizedPerms: [ProfilePermission.READ],
    excludedRoles: [
      Role.OPERATOR,
      Role.SERVICE_TECHNICIAN,
      Role.POC_ROUTEMANAGER,
    ],
    secure: true,
    component: UserList,
  },
  [RouteName.ASSET_DETAILS]: {
    name: RouteName.ASSET_DETAILS,
    path: [
      '/assets/:assetsId',
      '/organizations/:organizationsId/assets/:assetsId',
      '/organizations/:organizationsId/fleets/:fleetsId/assets/:assetsId',
      '/organizations/:organizationsId/hubs/:hubsId/assets/:assetsId',
      '/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId/assets/:assetsId',
    ],
    authorizedPerms: [AssetPermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: AssetDetails,
  },
  [RouteName.ORG_LIST]: {
    name: RouteName.ORG_LIST,
    path: AppRoutePaths.ORGANIZATION_LIST,
    authorizedPerms: [OrganizationPermission.READ],
    authorizedRoles: [Role.SYSTEM_MANAGER, Role.SYSTEM_READER],
    secure: true,
    component: OrganizationList,
  },
  [RouteName.ORG_DETAILS]: {
    name: RouteName.ORG_DETAILS,
    path: '/organizations/:organizationsId',
    authorizedPerms: [OrganizationPermission.READ],
    authorizedRoles: [
      Role.SYSTEM_MANAGER,
      Role.ORG_MANAGER,
      Role.SYSTEM_READER,
      Role.POC_GROCERYMANAGER,
    ],
    secure: true,
    component: OrganizationDetails,
  },
  [RouteName.USER_DETAILS]: {
    name: RouteName.USER_DETAILS,
    path: [
      '/users/:usersId',
      '/organizations/:organizationsId/users/:usersId',
      '/organizations/:organizationsId/fleets/:fleetsId/users/:usersId',
      '/organizations/:organizationsId/hubs/:hubsId/users/:usersId',
      '/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId/users/:usersId',
    ],
    authorizedPerms: [ProfilePermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: UserDetails,
  },
  [RouteName.HUB_LIST]: {
    name: RouteName.HUB_LIST,
    path: '/organizations/:organizationsId/hubs',
    authorizedPerms: [HubPermission.READ],
    authorizedRoles: [
      Role.SYSTEM_MANAGER,
      Role.ORG_MANAGER,
      Role.SERVICE_TECHNICIAN,
      Role.SYSTEM_READER,
    ],
    secure: true,
    component: HubList,
  },
  [RouteName.HUB_DETAILS]: {
    name: RouteName.HUB_DETAILS,
    path: '/organizations/:organizationsId/hubs/:hubsId',
    authorizedPerms: [HubPermission.READ],
    excludedRoles: [Role.FLEET_MANAGER, Role.OPERATOR],
    secure: true,
    component: HubDetails,
  },
  [RouteName.FLEET_LIST]: {
    name: RouteName.FLEET_LIST,
    path: [
      '/organizations/:organizationsId/fleets',
      '/organizations/:organizationsId/hubs/:hubsId/fleets',
    ],
    authorizedPerms: [FleetPermission.READ],
    excludedRoles: [Role.FLEET_MANAGER, Role.OPERATOR],
    secure: true,
    component: FleetList,
  },
  [RouteName.FLEET_DETAILS]: {
    name: RouteName.FLEET_DETAILS,
    path: ['/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId'],
    authorizedPerms: [FleetPermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: FleetDetails,
  },
  [RouteName.EDIT_FLEET_CONFIGURATION]: {
    name: RouteName.EDIT_FLEET_CONFIGURATION,
    path: [
      '/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId/editConfigs',
    ],
    authorizedPerms: [FleetPermission.UPDATE],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: EditFleetConfiguration,
  },
  [RouteName.MAP]: {
    name: RouteName.MAP,
    path: [
      '/organizations/:organizationsId/map',
      '/organizations/:organizationsId/hubs/:hubsId/map',
      '/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId/map',
    ],
    authorizedPerms: [AssetPermission.READ, OrganizationPermission.READ],
    authorizedRoles: [
      Role.SYSTEM_MANAGER,
      Role.ORG_MANAGER,
      Role.HUB_MANAGER,
      Role.FLEET_MANAGER,
      Role.SYSTEM_READER,
    ],
    secure: true,
    component: MapView,
  },
  [RouteName.DASHBOARD]: DASHBOARD_ROUTE,
  [RouteName.USER_TERMS_AND_CONDITIONS]: {
    name: RouteName.USER_TERMS_AND_CONDITIONS,
    path: '/users/:usersId/terms-and-conditions',
    authorizedPerms: [],
    secure: true,
    component: UserTermsConditions,
  },
  [RouteName.ORGANIZATION_TERMS_AND_CONDITIONS]: {
    name: RouteName.ORGANIZATION_TERMS_AND_CONDITIONS,
    path: '/organizations/:organizationsId/terms-and-conditions',
    authorizedPerms: [],
    secure: true,
    component: OrganizationTermsConditions,
  },
  [RouteName.UTILIZATION_REPORT]: {
    name: RouteName.UTILIZATION_REPORT,
    path: ['/utilization/report'],
    authorizedPerms: [AssetPermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: DailyUtilizationSummary,
    requiredFeatureFlags: [UTILIZATION_FLAG_NAME],
  },

  [RouteName.SIMULATION_LIST]: {
    name: RouteName.SIMULATION_LIST,
    path: ['/organizations/:organizationsId/simulations'],
    authorizedPerms: [OrganizationPermission.READ, JobPermission.READ],
    authorizedRoles: [Role.SYSTEM_MANAGER, Role.ORG_MANAGER],
    requiredFeatureFlags: [SIMULATION_FLAG_NAME],
    secure: true,
    component: SimulationListView,
  },
  [RouteName.ASSET_LIST_REPORT]: {
    name: RouteName.ASSET_LIST_REPORT,
    path: [
      AppRoutePaths.ASSET_LIST,
      '/organizations/:organizationsId/assetReport',
      '/organizations/:organizationsId/fleets/:fleetsId/assetReport',
      '/organizations/:organizationsId/hubs/:hubsId/assetReport',
      '/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId/assetReport',
      FLEET_VEHICLE_LIST,
    ],
    authorizedPerms: [AssetPermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: AssetListReportView,
  },
  [RouteName.MRT_ASSET_LIST_REPORT]: {
    name: RouteName.MRT_ASSET_LIST_REPORT, // remove once MRT integration is complete
    path: [MRT_FLEET_VEHICLE_LIST],
    authorizedPerms: [AssetPermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: MRTAssetListReportView,
    requiredFeatureFlags: [MRT_FLAG_NAME],
  },
  [RouteName.PRIVACY]: {
    name: RouteName.PRIVACY,
    path: ['/privacy-statement'],
    authorizedPerms: [],
    secure: false,
    component: PrivacyStatement,
  },
  [RouteName.USER_TERMS]: {
    name: RouteName.USER_TERMS,
    path: ['/user-terms'],
    authorizedPerms: [],
    secure: false,
    component: UserTerms,
  },
  [RouteName.INSIGHT_LIST]: {
    name: RouteName.INSIGHT_LIST,
    path: ['/insights'],
    authorizedPerms: [InsightPermission.READ],
    secure: true,
    component: InsightList,
    requiredFeatureFlags: [INSIGHTS_FLAG_NAME],
  },
  [RouteName.INSIGHT_DETAILS]: {
    name: RouteName.INSIGHT_DETAILS,
    path: ['/insights/details'],
    authorizedPerms: [InsightPermission.READ],
    secure: true,
    component: InsightDetails,
    requiredFeatureFlags: [INSIGHTS_FLAG_NAME],
  },
  [RouteName.ASSET_INSPECTIONS_LIST]: {
    name: RouteName.ASSET_INSPECTIONS_LIST,
    path: ['/inspections'],
    authorizedPerms: [InspectionPermission.READ],
    secure: true,
    component: AssetInspectionsListView,
    requiredFeatureFlags: [DVIC_FLAG_NAME],
  },
  [RouteName.ASSET_INSPECTIONS_DETAILS]: {
    name: RouteName.ASSET_INSPECTIONS_DETAILS,
    path: ['/inspections/details'],
    authorizedPerms: [InspectionPermission.READ],
    secure: true,
    component: AssetInspectionsDetailsView,
    requiredFeatureFlags: [DVIC_FLAG_NAME],
  },
  [RouteName.DISPATCH]: {
    name: RouteName.DISPATCH,
    path: ['/dispatch'],
    authorizedPerms: [WorkPlanPermission.READ],
    authorizedRoles: [Role.FLEET_MANAGER],
    secure: true,
    component: DispatchManagementSpace,
    requiredFeatureFlags: [DISPATCH_MANAGEMENT_MVP_FLAG_NAME],
  },
  [RouteName.WORK_PLAN_DETAILS]: {
    name: RouteName.WORK_PLAN_DETAILS,
    path: ['/workplans/:workPlanId'],
    authorizedPerms: [WorkPlanPermission.READ],
    authorizedRoles: [Role.FLEET_MANAGER],
    secure: true,
    component: WorkPlanDetailsSpace,
    requiredFeatureFlags: [DISPATCH_MANAGEMENT_MVP_FLAG_NAME],
  },
  [RouteName.CONNECTED_CAMERAS]: {
    name: RouteName.CONNECTED_CAMERAS,
    path: ['/connected-cameras'],
    authorizedPerms: [AssetPermission.READ],
    authorizedRoles: [Role.FLEET_MANAGER],
    secure: true,
    component: ConnectedCamerasView,
  },
  [RouteName.TRIP_LOGGER]: {
    name: RouteName.TRIP_LOGGER,
    path: ['/trip-logger'],
    authorizedPerms: [AssetPermission.READ],
    authorizedRoles: [Role.FLEET_MANAGER],
    secure: true,
    component: TripLoggerView,
    requiredFeatureFlags: [TRIP_LOGGER_FLAG_NAME],
  },

  // NEW PAGES FOR CS PLATFORM
  [RouteName.CS_PLATFORM_ASSET_DETAILS]: {
    name: RouteName.CS_PLATFORM_ASSET_DETAILS,
    path: [FLEET_VEHICLE_LIST_DETAILS],
    authorizedPerms: [AssetPermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: CSPlatformAssetDetails,
  },
  [RouteName.CS_PLATFORM_ORDER_LIST]: {
    name: RouteName.CS_PLATFORM_ORDER_LIST,
    path: [FLEET_VEHICLE_ORDERS_LIST],
    authorizedPerms: [
      VehicleOrderPermission.READ,
      VehicleOrderPermission.UPDATE,
      VehicleOrderPermission.DELETE,
      VehicleOrderPermission.CREATE,
    ],
    secure: true,
    component: OrderList,
  },
  [RouteName.CS_PLATFORM_ORDER_DETAILS]: {
    name: RouteName.CS_PLATFORM_ORDER_DETAILS,
    path: [FLEET_VEHICLE_ORDERS_DETAILS],
    authorizedPerms: [
      VehicleOrderPermission.READ,
      VehicleOrderPermission.UPDATE,
    ],
    secure: true,
    component: CSVehicleOrderDetails,
  },
  [RouteName.CS_PLATFORM_DASHBOARD]: {
    name: RouteName.CS_PLATFORM_DASHBOARD,
    path: [FLEET_HOME],
    authorizedPerms: [],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: CSPlatformDashboardView,
  },
  [RouteName.CS_PLATFORM_SETTINGS]: {
    name: RouteName.CS_PLATFORM_SETTINGS,
    path: [
      SETTINGS_NOTIFICATIONS_VEHICLES,
      `${SETTINGS_NOTIFICATIONS_VEHICLES}/:viewType`,
    ],
    authorizedRoles: NOTIFICATIONS_REQUIRED_ROLES,
    authorizedPerms: [],
    secure: true,
    component: CSPlatformSettingsView,
  },
  [RouteName.CS_PLATFORM_USERS_NOTIFICATIONS_SETTING]: {
    name: RouteName.CS_PLATFORM_USERS_NOTIFICATIONS_SETTING,
    path: [SETTINGS_NOTIFICATIONS_USERS],
    authorizedRoles: NOTIFICATIONS_REQUIRED_ROLES,
    authorizedPerms: [],
    secure: true,
    component: CSPlatformSettingsNotificationsUsersView,
  },
  [RouteName.CS_PLATFORM_ACCOUNTS_NOTIFICATIONS_SETTING]: {
    name: RouteName.CS_PLATFORM_ACCOUNTS_NOTIFICATIONS_SETTING,
    path: [SETTINGS_NOTIFICATIONS_ACCOUNT],
    authorizedRoles: NOTIFICATIONS_REQUIRED_ROLES,
    authorizedPerms: [],
    secure: true,
    component: CSPlatformSettingsNotificationsAccountsView,
  },
  [RouteName.CS_PLATFORM_NOTIFICATION_LIST]: {
    name: RouteName.CS_PLATFORM_NOTIFICATION_LIST,
    path: [FLEET_NOTIFICATIONS],
    authorizedRoles: NOTIFICATIONS_REQUIRED_ROLES,
    authorizedPerms: [AlertPermission.READ],
    secure: true,
    component: CSPlatformNotificationListWrapper,
  },
  [RouteName.CS_PLATFORM_ACCOUNT_PREFERENCES]: {
    name: RouteName.CS_PLATFORM_ACCOUNT_PREFERENCES,
    path: ['account/profile/preferences'],
    authorizedPerms: [ProfilePermission.READ, ProfilePermission.UPDATE],
    secure: true,
    component: CSPlatformAccountPreferencesView,
  },
  [RouteName.CS_PLATFORM_ACCOUNT_PROFILE]: {
    name: RouteName.CS_PLATFORM_ACCOUNT_PROFILE,
    path: ['/account/profile/personal'],
    authorizedPerms: [ProfilePermission.READ],
    secure: true,
    component: CSPlatformAccountProfileView,
  },
  [RouteName.ACCOUNT_PROFILE_PERSONAL]: {
    name: RouteName.CS_PLATFORM_SETTINGS,
    path: [ACCOUNT_PROFILE_PERSONAL_PATH],
    authorizedPerms: [],
    secure: true,
    component: CSPlatformAccountProfileView,
  },
  [RouteName.ACCOUNT_PREFERENCES_LANGUAGE_REGION]: {
    name: RouteName.CS_PLATFORM_SETTINGS,
    path: [ACCOUNT_PREFERENCES_LANGUAGE_REGION_PATH],
    authorizedPerms: [],
    secure: true,
    component: CSPlatformAccountPreferencesView,
  },
};

export const Routes: RouteConfig[] = Object.values(APP_ROUTES_CONFIG_MAPPING);
