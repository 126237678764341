import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isExpanded: false,
};

export const navDrawerSlice = createSlice({
  name: 'navDrawer',
  initialState,
  reducers: {
    setNavDrawerIsExpanded: (state, action: PayloadAction<boolean>) => {
      state.isExpanded = action.payload;
    },
    toggleNavDrawer: (state) => {
      state.isExpanded = !state.isExpanded;
    },
  },
});

export const { setNavDrawerIsExpanded, toggleNavDrawer } =
  navDrawerSlice.actions;

export const navDrawerReducer = navDrawerSlice.reducer;
