import {
  ApiAddressFields,
  mapAddressToApiResponse,
} from '~/common/mappers/common.mappers';
import { PartialEntity } from '~/common/models/common.model';
import { Fleet, FleetHomeLocation } from '~/common/models/fleet.model';
import { Hub } from '~/common/models/hub.model';
import { Organization } from '~/common/models/organization.model';
import {
  formatDateStringToDateTimeAPI,
  formatDateTimeAPIToDateTimeString,
} from '~/common/utils/date-time.utils';

import { ValidFleetForm } from '../form/FleetForm';

export type ApiFleetFields = {
  id: string;
  name: string;
  organization?: PartialEntity<Organization>;
  hub?: PartialEntity<Hub>;
  description?: string;
  configuration?: {
    lastUpdated: string;
    value: {
      autolockEnabled: boolean;
      autolockTimeout: number;
      proximityUnlockEnabled: boolean;
      supervisedDriveModeEnabled?: boolean;
      speedWarningEnabled?: boolean;
      speedWarningThreshold?: number;
      buckleToDriveEnabled?: boolean;
      timeZone?: string;
      chargingScheduleEnabled?: boolean;
      homeLocationLatitude?: number;
      homeLocationLongitude?: number;
      chargingScheduleTargetChargeLevel?: number;
      chargingScheduleDepartureTime?: string;
    };
  };
  creationDate?: string;
  organizationEntityConfiguration?: {
    chargingScheduleHomeLocationDisplayAddress?: string;
    safeFrozenGroceryThreshold?: number;
    safeRefrigeratedGroceryThreshold?: number;
  };
};

export type ApiFleetFormFields = {
  hubId: string;
  name: string;
  description?: string;
  configuration: {
    autolockEnabled: boolean;
    autolockTimeout: number;
    proximityUnlockEnabled: boolean;
    timeZone: string | null;
    supervisedDriveModeEnabled?: boolean;
    speedWarningEnabled?: boolean;
    speedWarningThreshold?: number;
    buckleToDriveEnabled?: boolean;
    chargingScheduleEnabled?: boolean;
    homeLocationLatitude?: number;
    homeLocationLongitude?: number;
    chargingScheduleTargetChargeLevel?: number;
    chargingScheduleDepartureTime?: string;
  };
  address?: ApiAddressFields;
  organizationEntityConfiguration?: {
    chargingScheduleHomeLocationDisplayAddress?: string;
    safeRefrigeratedGroceryThreshold: number;
    safeFrozenGroceryThreshold: number;
  };
};

export const mapApiResponseToFleet = (apiFleet: ApiFleetFields): Fleet => {
  const {
    autolockTimeout,
    autolockEnabled,
    proximityUnlockEnabled,
    timeZone,
    homeLocationLatitude,
    homeLocationLongitude,
    chargingScheduleDepartureTime,
    ...otherConfigs
  } = apiFleet.configuration?.value || {};
  const traceConfigs = {
    autoLockEnabled: autolockEnabled || false,
    autoLockTimeout: autolockTimeout || 10000,
    proximityUnlockEnabled: proximityUnlockEnabled || false,
  };
  const coldChainConfigs = {
    safeRefrigeratedGroceryThreshold: apiFleet.organizationEntityConfiguration
      ?.safeRefrigeratedGroceryThreshold
      ? apiFleet.organizationEntityConfiguration
          .safeRefrigeratedGroceryThreshold * 1000
      : undefined,
    safeFrozenGroceryThreshold: apiFleet.organizationEntityConfiguration
      ?.safeFrozenGroceryThreshold
      ? apiFleet.organizationEntityConfiguration.safeFrozenGroceryThreshold *
        1000
      : undefined,
  };
  const homeLocation = {
    address:
      apiFleet.organizationEntityConfiguration
        ?.chargingScheduleHomeLocationDisplayAddress,
    location: {
      latitude: homeLocationLatitude,
      longitude: homeLocationLongitude,
    },
  } as FleetHomeLocation;

  return {
    id: apiFleet.id || '',
    organization: {
      id: apiFleet.organization?.id || '',
      name: apiFleet.organization?.name || '',
    },
    hub: { id: apiFleet.hub?.id || '', name: apiFleet.hub?.name || '' },
    name: apiFleet.name || '',
    description: apiFleet.description || '',
    ...traceConfigs,
    creationDate: apiFleet.creationDate,
    lastUpdated: apiFleet?.configuration?.lastUpdated,
    configuration: apiFleet.configuration?.value
      ? {
          ...traceConfigs,
          ...otherConfigs,
          ...coldChainConfigs,
          homeLocation,
          chargingScheduleDepartureTime: formatDateTimeAPIToDateTimeString(
            chargingScheduleDepartureTime
          ),
        }
      : undefined,
    timeZone,
  };
};

const toSeconds = ({
  hours = 0,
  minutes = 0,
}: {
  hours?: number;
  minutes?: number;
}) => hours * 3600 + minutes * 60;

export const mapFleetFormToApiRequest = (
  fleetForm: ValidFleetForm,
  defaultConfigs?: boolean
): ApiFleetFormFields => ({
  hubId: fleetForm.hub.id,
  name: fleetForm.name.trim(),
  description: fleetForm.description || '',
  address: fleetForm.address
    ? mapAddressToApiResponse(fleetForm.address)
    : undefined,
  configuration: {
    autolockEnabled: defaultConfigs ? false : fleetForm.autoLockEnabled,
    autolockTimeout: fleetForm.autoLockTimeout || 0,
    proximityUnlockEnabled: fleetForm.proximityUnlockEnabled || false,
    supervisedDriveModeEnabled: defaultConfigs
      ? false
      : fleetForm.supervisedDriveModeEnabled,
    buckleToDriveEnabled: defaultConfigs
      ? false
      : fleetForm.buckleToDriveEnabled,
    speedWarningEnabled: defaultConfigs ? false : fleetForm.speedWarningEnabled,
    speedWarningThreshold: fleetForm.speedWarningThreshold,
    timeZone: fleetForm?.timeZone || null,
    chargingScheduleEnabled: defaultConfigs
      ? false
      : fleetForm.chargingScheduleEnabled,
    homeLocationLatitude: fleetForm.homeLocation?.location?.latitude,
    homeLocationLongitude: fleetForm.homeLocation?.location?.longitude,
    chargingScheduleTargetChargeLevel:
      fleetForm?.chargingScheduleTargetChargeLevel,
    chargingScheduleDepartureTime: formatDateStringToDateTimeAPI(
      fleetForm.chargingScheduleDepartureTime
    ),
  },
  organizationEntityConfiguration: {
    chargingScheduleHomeLocationDisplayAddress: fleetForm.homeLocation?.address,
    safeRefrigeratedGroceryThreshold: toSeconds({
      hours: fleetForm.refrigeratedHr,
      minutes: fleetForm.refrigeratedMin,
    }),
    safeFrozenGroceryThreshold: toSeconds({
      hours: fleetForm.frozenHr,
      minutes: fleetForm.frozenMin,
    }),
  },
});
