import { NavBarConfig } from '@gm-commercial/navbar-model';
import { Permission, Profile, Role } from '@gm-commercial/profile-model';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { SvgIconProps } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Dispatch } from 'redux';

import { DEFAULT_FIRST_PAGE } from '~/common/components/table/configs/table.configs';
import {
  APP_ROUTES_CONFIG_MAPPING,
  RouteParams,
} from '~/common/configs/route.config';
import {
  SearchType,
  SessionConfigType,
} from '~/common/constants/common.constant';
import { AssetReportType } from '~/common/models/asset.model';
import {
  CONNECTED_CAMERAS_FLAG_NAME,
  DVIC_FLAG_NAME,
  FeatureFlagValueType,
  INSIGHTS_FLAG_NAME,
  SIMULATION_FLAG_NAME,
  TRIP_LOGGER_FLAG_NAME,
} from '~/common/models/featureFlags.model';
import { AppRoutePaths, RouteName } from '~/common/models/route.model';
import { ScheduledDateRange } from '~/common/models/workplan.model';
import { findRoute, makeRoutePath } from '~/common/utils/route/route.utils';
import { resetAssetListReportSessionConfig } from '~/features/assets/assetsSlice';
import {
  JobsSessionViewType,
  setJobsSessionConfig,
} from '~/features/dispatch/UploadHistory/jobsSlice';
import {
  setWorkPlanListScheduledDateRange,
  setWorkPlansListPageNumber,
  setWorkPlansListSearchCriteria,
} from '~/features/dispatch/workplans/workPlansSlice';
import { setInspectionsSessionConfig } from '~/features/inspections/inspectionsSlice';
import { InspectionsSessionViewType } from '~/features/inspections/inspectionsSlice.model';
import { setEffectiveContext } from '~/features/profile/profileSlice';

import InspectionsIcon from '../../../assets/icons/inspectionsIcon.svg?react';
import AssetsIcon from '../../../assets/icons/leftMenu/assetsIcon.svg?react';
import SimulationIcon from '../../../assets/icons/leftMenu/atom-01.svg?react';
import DashboardIcon from '../../../assets/icons/leftMenu/dashboardIcon.svg?react';
import DispatchIcon from '../../../assets/icons/leftMenu/dispatchIcon.svg?react';
import HelpIcon from '../../../assets/icons/leftMenu/helpIcon.svg?react';
import InsightsIcon from '../../../assets/icons/leftMenu/insights.svg?react';
import MapIcon from '../../../assets/icons/leftMenu/mapNavIcon.svg?react';
import OrganizationIcon from '../../../assets/icons/leftMenu/organizationIcon.svg?react';
import TripIcon from '../../../assets/icons/leftMenu/tripIcon.svg?react';
import UserProfileIcon from '../../../assets/icons/leftMenu/userProfileIcon.svg?react';
import { CS_NAVBAR_MESSAGES } from '../CsNavDrawer.messages';

export interface NavListItem {
  testId: string;
  label: string | ReactNode;
  path: string;
  permissions: Permission[];
  icon?: SvgIconProps;
  callback?: () => void;
  routeName?: RouteName;
  excludedRoles?: Role[];
  requiredRoles?: Role[];
}

/**
 * Type guard to distinguish between NavListItem and NavBarItem
 * @param item either a NavListItem or NavBarItem
 * @returns `true` if item contains props specific to NavListItem, `false` otherwise
 */
export const isNavListItem = (item: unknown): item is NavListItem => {
  const test = item as NavListItem;
  return (
    test.routeName !== undefined ||
    test.excludedRoles !== undefined ||
    test.requiredRoles !== undefined ||
    test.permissions !== undefined
  );
};

export type LegacyNavDrawerProps = Omit<NavBarConfig, 'items'> & {
  items: NavListItem[];
};

export enum LegacyPath {
  DASHBOARD = 'dashboard',
  ORGANIZATIONS = 'organizations',
  USERS = 'users',
  INSPECTIONS = 'inspections',
  INSIGHTS = 'insights',
  ASSET_LIST = 'assetReport',
}

const getPathForOrgFeature = (
  findFlagScope: (flagName: string) => FeatureFlagValueType | undefined,
  routeName: RouteName,
  featureFlagName: string,
  params?: RouteParams
) => {
  const organizationsId = findFlagScope(featureFlagName) as string;
  return organizationsId
    ? makeRoutePath(
        findRoute(routeName),
        { ...params, organizationsId },
        false,
        true
      )
    : '';
};

export const getLegacyNavDrawerConfig = ({
  messages,
  profileParams,
  dispatch,
  sessionId,
  findFlagScope,
}: {
  messages: typeof CS_NAVBAR_MESSAGES.legacyMenu;
  profileParams: Profile['permissionsContextIds'];
  dispatch?: Dispatch;
  sessionId?: string;
  findFlagScope?: (flagName: string) => FeatureFlagValueType | undefined;
}): LegacyNavDrawerProps => {
  const context = {
    fleetsId: profileParams?.fleetsId || undefined,
    hubsId: profileParams?.hubsId || undefined,
    organizationsId: profileParams?.organizationsId || undefined,
  };
  const legacyNavDrawerConfig = {
    homePath: '/',
    open: false,
    toggleable: true,
    items: [
      {
        icon: (
          <DashboardIcon
            style={{ margin: '0 0 0 2px' }}
            data-testid="dashboard-icon"
          />
        ),
        path: makeRoutePath(findRoute(RouteName.DASHBOARD), context),
        permissions:
          APP_ROUTES_CONFIG_MAPPING[RouteName.DASHBOARD].authorizedPerms,
        requiredRoles:
          APP_ROUTES_CONFIG_MAPPING[RouteName.DASHBOARD].authorizedRoles,
        excludedRoles: [Role.SYSTEM_MANAGER],
        label: messages['dashboard:title'],
        testId: 'dashboard-nav-list-item',
      },
      {
        icon: <MapIcon data-testid="map-icon" />,
        path: makeRoutePath(findRoute(RouteName.MAP), context),
        permissions: APP_ROUTES_CONFIG_MAPPING[RouteName.MAP].authorizedPerms,
        requiredRoles: APP_ROUTES_CONFIG_MAPPING[RouteName.MAP].authorizedRoles,
        excludedRoles: [Role.SYSTEM_MANAGER],
        label: messages['common:map'],
        testId: 'map-nav-list-item',
      },
      {
        icon: <OrganizationIcon data-testid="org-list" />,
        path: '/organizations',
        permissions:
          APP_ROUTES_CONFIG_MAPPING[RouteName.ORG_LIST].authorizedPerms,
        requiredRoles:
          APP_ROUTES_CONFIG_MAPPING[RouteName.ORG_LIST].authorizedRoles,
        label: messages['common:organization_plural'],
        testId: 'organization-nav-list-item',
      },
      {
        icon: <OrganizationIcon data-testid="org-details" />,
        path: makeRoutePath(findRoute(RouteName.ORG_DETAILS), context),
        permissions:
          APP_ROUTES_CONFIG_MAPPING[RouteName.ORG_DETAILS].authorizedPerms,
        requiredRoles:
          APP_ROUTES_CONFIG_MAPPING[RouteName.ORG_DETAILS].authorizedRoles,
        label: messages['common:organization'],
        testId: 'org-details-nav-list-item',
      },
      {
        icon: <OrganizationIcon data-testid="hub-details" />,
        path: makeRoutePath(findRoute(RouteName.HUB_DETAILS), context),
        permissions:
          APP_ROUTES_CONFIG_MAPPING[RouteName.ORG_DETAILS].authorizedPerms,
        requiredRoles: [Role.HUB_MANAGER],
        label: messages['common:hub'],
        testId: 'hub-details-nav-list-item',
      },
      {
        icon: <OrganizationIcon data-testid="fleet-details" />,
        path: makeRoutePath(findRoute(RouteName.FLEET_DETAILS), context),
        permissions:
          APP_ROUTES_CONFIG_MAPPING[RouteName.FLEET_DETAILS].authorizedPerms,
        requiredRoles: [Role.FLEET_MANAGER],
        label: messages['common:fleet'],
        testId: 'fleet-details-nav-list-item',
      },
      {
        icon: <DispatchIcon style={{ width: '25px' }} />,
        path: makeRoutePath(
          findRoute(RouteName.DISPATCH),
          context,
          false,
          true
        ),
        permissions:
          APP_ROUTES_CONFIG_MAPPING[RouteName.DISPATCH].authorizedPerms,
        requiredRoles:
          APP_ROUTES_CONFIG_MAPPING[RouteName.DISPATCH].authorizedRoles,
        label: messages['common:dispatch.title'],
        callback: () => {
          if (dispatch && sessionId) {
            dispatch(
              setWorkPlansListSearchCriteria({
                [sessionId]: {
                  searchCriteria: {
                    searchType: SearchType.WORK_PLAN_NAME,
                    input: '',
                  },
                },
              })
            );
            dispatch(
              setWorkPlanListScheduledDateRange({
                [sessionId]: {
                  selectedScheduledDateRange: ScheduledDateRange.TODAY,
                },
              })
            );
            dispatch(
              setWorkPlansListPageNumber({
                [sessionId]: {
                  page: DEFAULT_FIRST_PAGE,
                },
              })
            );
            dispatch(
              setJobsSessionConfig({
                [JobsSessionViewType.WORK_PLANS_UPLOAD_HISTORY]: {
                  [sessionId]: {
                    searchCriteria: {
                      searchType: SearchType.FILE_NAME,
                      input: '',
                    },
                    selectedScheduledDateRange: ScheduledDateRange.TODAY,
                    page: DEFAULT_FIRST_PAGE,
                  },
                },
              })
            );
          }
        },
        testId: 'dispatch-management-nav-list-item',
      },
      {
        icon: (
          <CameraAltOutlinedIcon
            fontSize="small"
            data-testid="connected-cameras"
          />
        ),
        path: findFlagScope
          ? getPathForOrgFeature(
              findFlagScope,
              RouteName.CONNECTED_CAMERAS,
              CONNECTED_CAMERAS_FLAG_NAME,
              context
            )
          : '',
        permissions:
          APP_ROUTES_CONFIG_MAPPING[RouteName.CONNECTED_CAMERAS]
            .authorizedPerms,
        label: messages['connectedCameras:title'],
        testId: 'connected-cameras-nav-list-item',
      },
      {
        icon: <TripIcon data-testid="trip-logger" />,
        path: findFlagScope
          ? getPathForOrgFeature(
              findFlagScope,
              RouteName.TRIP_LOGGER,
              TRIP_LOGGER_FLAG_NAME,
              context
            )
          : '',
        permissions:
          APP_ROUTES_CONFIG_MAPPING[RouteName.TRIP_LOGGER].authorizedPerms,
        label: messages['tripLogger:title'],
        testId: 'trip-logger-nav-list-item',
      },
      {
        icon: <AssetsIcon style={{ width: '25px' }} />,
        path: makeRoutePath(findRoute(RouteName.ASSET_LIST_REPORT), context),
        permissions:
          APP_ROUTES_CONFIG_MAPPING[RouteName.ASSET_LIST_REPORT]
            .authorizedPerms,
        label: messages['common:asset_plural'],
        callback: () => {
          if (dispatch && sessionId) {
            dispatch(setEffectiveContext(undefined));
            dispatch(
              resetAssetListReportSessionConfig({
                viewType: SessionConfigType.LIST_VIEW,
                sessionId,
                activeReport: AssetReportType.FLEET_READINESS,
              })
            );
          }
        },
        testId: 'asset-list-nav-list-item',
      },
      {
        icon: <InsightsIcon />,
        path: findFlagScope
          ? getPathForOrgFeature(
              findFlagScope,
              RouteName.INSIGHT_LIST,
              INSIGHTS_FLAG_NAME,
              context
            )
          : '',
        permissions:
          APP_ROUTES_CONFIG_MAPPING[RouteName.INSIGHT_LIST].authorizedPerms,
        excludedRoles: [Role.FLEET_MANAGER],
        label: messages['insight:title'],
        testId: 'insight-list-nav-list-item',
      },
      {
        icon: <InsightsIcon />,
        path: findFlagScope
          ? getPathForOrgFeature(
              findFlagScope,
              RouteName.INSIGHT_DETAILS,
              INSIGHTS_FLAG_NAME,
              context
            )
          : '',
        permissions:
          APP_ROUTES_CONFIG_MAPPING[RouteName.INSIGHT_DETAILS].authorizedPerms,
        requiredRoles: [Role.FLEET_MANAGER],
        label: messages['insight:title'],
        testId: 'insight-details-nav-list-item',
      },
      {
        icon: <InspectionsIcon />,
        path: findFlagScope
          ? getPathForOrgFeature(
              findFlagScope,
              RouteName.ASSET_INSPECTIONS_LIST,
              DVIC_FLAG_NAME,
              context
            )
          : '',
        permissions:
          APP_ROUTES_CONFIG_MAPPING[RouteName.ASSET_INSPECTIONS_LIST]
            .authorizedPerms,
        label: messages['inspection:assetsInspections'],
        callback: () => {
          const paramSessionId =
            context?.fleetsId ||
            context?.hubsId ||
            context?.organizationsId ||
            sessionId;

          if (dispatch && paramSessionId) {
            dispatch(
              setInspectionsSessionConfig({
                [InspectionsSessionViewType.INSPECTION_LIST]: {
                  [paramSessionId]: {
                    overviewTab: undefined,
                    currentDate: DateTime.local().toString(),
                    filterCriteria: {
                      outcome: [],
                      severity: [],
                    },
                    searchCriteria: {
                      searchType: SearchType.ASSET,
                      input: '',
                    },
                  },
                },
              })
            );
          }
        },
        testId: 'inspection-list-nav-list-item',
      },
      {
        icon: <UserProfileIcon />,
        path: makeRoutePath(findRoute(RouteName.USER_LIST), context),
        permissions:
          APP_ROUTES_CONFIG_MAPPING[RouteName.USER_LIST].authorizedPerms,
        excludedRoles:
          APP_ROUTES_CONFIG_MAPPING[RouteName.USER_LIST].excludedRoles,
        label: messages['common:user_plural'],
        testId: 'user-list-nav-list-item',
      },
      {
        icon: <SimulationIcon fontSize="small" />,
        path: findFlagScope
          ? getPathForOrgFeature(
              findFlagScope,
              RouteName.SIMULATION_LIST,
              SIMULATION_FLAG_NAME
            )
          : '',
        permissions:
          APP_ROUTES_CONFIG_MAPPING[RouteName.SIMULATION_LIST].authorizedPerms,
        requiredRoles:
          APP_ROUTES_CONFIG_MAPPING[RouteName.SIMULATION_LIST].authorizedRoles,
        label: (
          <Trans i18nKey={'simulation:navLabel'}>
            SIMULATION
            <br />
            ON DEMAND
          </Trans>
        ),
        testId: 'simulation-list-nav-list-item',
      },
      {
        label: messages['common:footer.support'],
        icon: <HelpIcon />,
        testId: 'support-nav-list-item',
        path: AppRoutePaths.SUPPORT,
        permissions: [],
      },
    ],
  };
  return {
    ...legacyNavDrawerConfig,
    items: legacyNavDrawerConfig.items.filter((item) => item.path !== ''),
  };
};
