import { NavBarConfig } from '@gm-commercial/navbar-model';
import { Profile } from '@gm-commercial/profile-model';
import { Dispatch } from 'redux';

import { AppNames } from '~/common/models/appNames.model';
import { FeatureFlagValueType } from '~/common/models/featureFlags.model';

import { CS_NAVBAR_MESSAGES } from '../CsNavDrawer.messages';
import { getAccountNavBarConfig } from './account.navDrawer.config';
import { getFleetNavBarConfig } from './fleet.navDrawer.config';
import {
  getLegacyNavDrawerConfig,
  LegacyPath,
} from './legacy.navDrawer.config';
import { getSettingsNavBarConfig } from './settings.navDrawer.config';

export const getCSNavBarConfig = ({
  appName,
  messages,
  profileParams,
  dispatch,
  sessionId,
  findFlagScope,
}: {
  messages: typeof CS_NAVBAR_MESSAGES;
  profileParams: Profile['permissionsContextIds'];
  appName?: AppNames;
  dispatch?: Dispatch;
  sessionId?: string;
  findFlagScope?: (flagName: string) => FeatureFlagValueType | undefined;
}): NavBarConfig => {
  if (!appName) {
    return { items: [] };
  }
  switch (appName) {
    case AppNames.FLEET:
      return getFleetNavBarConfig({
        messages: messages.fleetMenu,
        profileParams,
        findFlagScope,
      });
    case AppNames.ACCOUNT:
      return getAccountNavBarConfig({ messages: messages.accountMenu });
    case AppNames.SETTINGS:
      return getSettingsNavBarConfig({ messages: messages.settingsMenu });
    case AppNames.LEGACY:
      // Remove whenever legacy views no longer need to be supported
      return getLegacyNavDrawerConfig({
        messages: messages.legacyMenu,
        profileParams,
        sessionId,
        dispatch,
        findFlagScope,
      });
    default:
      return { items: [] };
  }
};

export const getAppNameFromPath = (path: string) => {
  const appName = Object.values(AppNames).find((appName) =>
    path.trim().startsWith(`/${appName}`)
  );

  // Ensures legacy views still show the old nav drawer items
  if (!appName) {
    if (
      Object.values(LegacyPath).find((legacyPath) =>
        path.trim().startsWith(`/${legacyPath}`)
      )
    ) {
      return AppNames.LEGACY;
    } else {
      return AppNames.FLEET;
    }
  }
  return appName;
};
