import { ListViewSession, OperationSession } from './common.model';
import {
  type VehicleOrderDetailsApiResponse,
  type VehicleOrderDetailsTableColumns,
  type VehicleOrderStatus,
  AddVehicleOrdersResult,
  VehicleOrderSummary,
} from './vehicleOrders.model';

export type PageTokens = {
  currentToken: string;
  previousToken: string;
  nextToken: string;
  firstPageItemIndex: number;
  totalItems: number;
};

export interface SelectedVehicleOrderCodes {
  [orderNumber: string]: number;
}

export interface VehicleOrderListSession
  extends ListViewSession,
    OperationSession {
  selectedVehicleOrderCodes: SelectedVehicleOrderCodes;
  filterModalOpen?: boolean;
  vehicleOrders: VehicleOrderDetailsApiResponse[];
  searchCriteria: string | undefined;
  filterCriteria: { status?: VehicleOrderStatus[] };
  sortOrder: {
    name: keyof Partial<VehicleOrderDetailsTableColumns>;
    direction: 'asc' | 'desc';
  };
  pageTokens: PageTokens;
}
export interface VehicleOrderDetailsSession extends OperationSession {
  details?: VehicleOrderDetailsApiResponse;
}
export interface VehicleOrderAddSession extends OperationSession {
  order?: AddVehicleOrdersResult;
  isAddOrder?: boolean;
}
export interface VehicleOrderDashboardSession extends OperationSession {
  summary?: VehicleOrderSummary;
}
export interface VehicleOrderRemoveSession extends OperationSession {
  orders?: string[];
}
export interface VehicleRecentOrderDashboardSession extends OperationSession {
  recentOrders?: VehicleOrderDetailsApiResponse[];
}

export enum VehicleOrderSessionViewType {
  VEHICLE_ORDER_LIST = 'Vehicle Order List',
  VEHICLE_ORDER_DETAILS = 'Vehicle Order Details',
  VEHICLE_ORDER_ADD = 'Vehicle Order Adds',
  VEHICLE_ORDER_LIST_EXPORT = 'Vehicle Order List Export',
  VEHICLE_ORDER_DASHBOARD = 'Vehicle Order Dashboard',
  VEHICLE_RECENT_ORDER_DASHBOARD = 'Vehicle Recent Order Dashboard',
  VEHICLE_ORDER_REMOVE = 'Remove Vehicle Orders',
}

export type VehicleOrderSessionConfig = {
  [VehicleOrderSessionViewType.VEHICLE_ORDER_LIST]?: {
    [key: string]: Partial<VehicleOrderListSession>;
  };
  [VehicleOrderSessionViewType.VEHICLE_ORDER_DETAILS]?: {
    [key: string]: Partial<VehicleOrderDetailsSession>;
  };
  [VehicleOrderSessionViewType.VEHICLE_ORDER_ADD]?: {
    [key: string]: Partial<VehicleOrderAddSession>;
  };
  [VehicleOrderSessionViewType.VEHICLE_ORDER_LIST_EXPORT]?: {
    [key: string]: Partial<OperationSession>;
  };
  [VehicleOrderSessionViewType.VEHICLE_ORDER_DASHBOARD]?: {
    [key: string]: Partial<VehicleOrderDashboardSession>;
  };
  [VehicleOrderSessionViewType.VEHICLE_RECENT_ORDER_DASHBOARD]?: {
    [key: string]: Partial<VehicleRecentOrderDashboardSession>;
  };
  [VehicleOrderSessionViewType.VEHICLE_ORDER_REMOVE]?: {
    [key: string]: Partial<VehicleOrderRemoveSession>;
  };
};
export interface VehicleOrdersState {
  sessionConfigs: VehicleOrderSessionConfig;
}
