import { COLOR_PALETTE } from '@brightdrop/mui-theme-override';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  headerWrapper: {
    borderBottom: `1px solid ${theme.new.color.line.hairline}`,
    boxShadow: 'none',
    zIndex: 1,
    '&.hideBar': { display: 'none' },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flex: '1 0 0',
    padding: '12px 16px',
    height: '64px',
    [theme.breakpoints.up('md')]: {
      padding: '12px 32px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '24px',
  },
  profileContainerButton: {
    borderRadius: '50%',
    height: '32px',
    minWidth: '32px',
    padding: 0,
    width: '32px',

    '&:hover, &:focus': {
      outline: `5px solid ${theme.new.color.brand[100]}`,
      outlineOffset: '0px',
    },

    '&:active': {
      outline: `5px solid ${theme.new.color.brand[200]}`,
      outlineOffset: '0px',
    },
  },
  profileContainer: {
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    padding: 0,
    backgroundColor: theme.palette.grey[100],
    border: `1px solid var(--color-line-hairline, ${COLOR_PALETTE.light.lightGray})`,
    color: theme.palette.text.secondary,
    fontSize: '0.9rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.isOpen': {
      outline: `5px solid ${theme.new.color.brand[200]}`,
      outlineOffset: '0px',
    },
  },
  viewAsHeaderWrapper: {
    zIndex: '400',
    backgroundColor: theme.palette.warning.light,
    '& ~ div .MuiContainer-root header.subTitleBar': {
      top: '50px',
    },
  },
  viewAsContainer: {
    height: '50px',
  },
  viewAsMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  viewAslink: {
    marginLeft: '1rem',
  },
}));

export default useStyles;
