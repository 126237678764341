import { makeStyles } from 'tss-react/mui';

import envolveLogo from '../../assets/images/envolveLogo.svg';
import gmLogo from '../../assets/images/gmLogo.svg';

const useStyles = makeStyles()((theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.common.white,
    borderRight: `1px solid ${theme.palette.border}`,
    [theme.breakpoints.down('md')]: {
      flex: '1',
    },
    '& svg.integrationIcon , & .MuiListItem-button:hover svg.integrationIcon': {
      fill: 'none',
    },
  },
  logoContainer: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    '& .logo': {
      backgroundImage: `url("${gmLogo}")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: '64px',
      padding: '16px 20px',
      width: '72px',
      [theme.breakpoints.up('md')]: {
        marginBottom: '12px',
        '&.isOpen': {
          backgroundImage: `url("${envolveLogo}")`,
          backgroundPositionX: 'left',
          marginLeft: '20px',
          width: 'auto',
        },
      },
    },
    '& .menu': {
      position: 'absolute',
      width: '24px',
      height: '24px',
      top: '30px',
      right: '5px',
      background: theme.palette.common.white,
      borderRadius: '40%',
      border: `1px solid ${theme.palette.grey[300]}`,
      '& svg': {
        width: '20px',
        height: '20px',
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      '&.isOpen': {
        display: 'none',
      },
    },
    '& .toggle': {
      display: 'none',
      '&.isOpen': {
        display: 'block',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
    },
  },
  titleContainer: {
    display: 'none',
    '&.isOpen': {
      display: 'block',
    },
    padding: '15px',
  },
  drawerOpen: {
    zIndex: 2,
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: 'auto',
    width: '75%',
    [theme.breakpoints.up('md')]: {
      width: '300px',
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 1,
    display: 'flex',
    height: 'auto',
    backgroundColor: theme.palette.common.white,
    width: '72px',
    overflow: 'visible',
    border: 'none',
    '& .MuiListItem-root': {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
  },
  navDrawerList: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },
  },
  drawerToggle: {
    display: 'block',
    marginBlockStart: 'auto',
    '& .MuiBox-root': {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '24px',
      button: {
        padding: '0',
      },
    },
    '&:not(.isOpen)': {
      transform: 'rotate(180deg)',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column-reverse',
      },
    },
    '&.isOpen': {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    button: {
      '&:focus-visible': {
        borderRadius: 0,
        outlineOffset: '1px',
        outline: `2px auto ${theme.new.color.brandPrimary}`,
      },
    },
  },
  list: {
    overflowY: 'auto',
    overflowX: 'hidden',
    top: '0',
    paddingTop: '0',
    '&.viewAsHeader': {
      '& li:first-of-type': {
        marginTop: '50px',
      },
    },
    '& a': {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiListItem-root': {
        height: '60px',
      },
    },
  },
  closeButton: {
    justifyContent: 'flex-end',
    padding: '0',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  closeIcon: {
    fontSize: '24px',
  },
  assetsIcon: {
    width: '25px',
    fill: theme.palette.text.primary,
  },
  dashboardIcon: {
    margin: '0 0 0 2px',
  },
  alertsIcon: {
    margin: '0 0 0 2px',
  },
  insightsIcon: {
    width: '25px',
  },
  inspectionsIcon: {
    '&.Mui-selected, &.Mui-selected:hover': {
      '& path': {
        fill: theme.palette.brand.main,
      },
    },
  },
  dispatchIcon: {
    width: '25px',
    fill: theme.palette.text.primary,
  },
}));

export default useStyles;
