import { Entity, NonNullablePick } from './common.model';

export enum SimulationGeography {
  ATLANTA = 'atlanta',
  DALLAS = 'dallas',
  DENVER = 'denver',
  DETROIT = 'detroit',
  LOS_ANGELES = 'los_angeles',
  PHILADELPHIA = 'philadelphia',
  NORTHEAST_CORRIDOR = 'northeast_corridor',
}

export enum FleetSize {
  ONE = '1',
  TEN = '10',
  ONE_HUNDRED = '100',
  ONE_THOUSAND = '1000',
  TEN_THOUSAND = '10000',
}

export enum SimulationRunTime {
  TEN_MINU = '600',
  ONE_HOUR = '3600',
  FOUR_HOURS = '14400',
  EIGHT_HOURS = '28800',
  TWENTY_FOUR_HOURS = '86400',
  ONE_WEEK = '604800',
}
export enum SimulationStatus {
  QUEUED = 'QUEUED',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  TERMINATED_BY_USER = 'TERMINATED',
  RETRYING = 'RETRYING',
  TERMINATION_REQUESTED = 'TERMINATION_REQUESTED',
}
export enum SimulationSpeed {
  FOLLOW_CLOCK = 'follow_clock',
  X1 = '1x',
  X2 = '2x',
  X5 = '5x',
  X10 = '10x',
  FULL_SPEED = 'max',
}
export enum StartTimeType {
  IMMEDIATE,
  FUTURE,
}

export enum NewSimFormFieldNames {
  NAME = 'name',
  GEOGRAPHY = 'geography',
  FLEET_SIZE = 'fleetSize',
  SPEED = 'speed',
  SYNTHETIC = 'synthetic',
  START_TIME_TYPE = 'startTimeType',
  START_TIME = 'startTime',
  SIM_RUN_TIME = 'simRunTime',
}

export enum ApiSimulationStatus {
  NOT_STARTED = 'not_started',
  ACTIVE = 'active',
  RETRYING = 'retrying',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  TERMINATED_BY_USER = 'terminated_by_user',
  TERMINATION_REQUESTED = 'termination_requested',
  TERMINATED_BY_TIMEOUT = 'terminated_by_timeout',
}

export enum SimulationOperation {
  CLONE = 'clone',
  STOP = 'stop',
  VIEW = 'view',
}

export enum SimulationFieldType {
  STATUS = 'status.status',
  FLEET_SIZE = 'parameters.size',
  GEOGRAPHY = 'parameters.geography',
  RUN_TIME = 'runtime.preferredDurationInS',
  NAME = 'parameters.name',
  JOB_TYPE = 'jobType',
}

export enum SimulationListColumnProperty {
  STATUS = 'status',
  NAME = 'name',
  FLEET_SIZE = 'fleetSize',
  GEOGRAPHY = 'geography',
  SPEED = 'speed',
  RUN_TIME = 'runTime',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  SYNTHETIC_TELEMERY = 'operation',
}

export type SimulationFormFields = {
  name: string;
  geography: SimulationGeography;
  fleetSize: FleetSize;
  speed: boolean;
  synthetic: boolean;
  startTimeType: StartTimeType;
  simRunTime: SimulationRunTime;
  startTime: string;
};

export interface CreateNewSimulationFormProp extends Entity {
  initialFormValue?: SimulationFormFields;
}

export type ValidNewSimFormFields = NonNullablePick<
  SimulationFormFields,
  | 'name'
  | 'geography'
  | 'fleetSize'
  | 'speed'
  | 'startTimeType'
  | 'startTime'
  | 'simRunTime'
> &
  Pick<SimulationFormFields, 'synthetic'>;

export interface SimulationAPIRequest {
  jobType: string;
  parameters: {
    name: string;
    geography: SimulationGeography;
    size: FleetSize;
    speed: SimulationSpeed;
    synthetic: boolean;
    preferredDurationInS: number;
  };
  runtime: {
    startAfter: string;
    preferredDurationInS: number;
  };
}

export interface Simulation extends Entity {
  status: SimulationStatus;
  fleetSize: FleetSize;
  geography: SimulationGeography;
  syntheticTelemetry: boolean;
  speed: SimulationSpeed;
  runTime: SimulationRunTime;
  operation: SimulationOperation;
  diagnostics: SimulationDiagnostics;
  userId?: string;
  organizationsId?: string;
  requestTime?: string;
  startTime?: string;
  endTime?: string;
}

export interface ApiSimulation {
  jobId: string;
  organizationId: string;
  jobType: string;
  parameters: {
    name: string;
    geography: SimulationGeography;
    size: FleetSize;
    speed: SimulationSpeed;
    synthetic: boolean;
  };
  runtime: {
    startAfter: string;
    preferredDurationInS: number;
  };
  createdDate: string;
  status: {
    status: ApiSimulationStatus;
    lastUpdatedTimestamp: string;
    diagnostics?: ApiSimulationDiagnostics;
    startedTimestamp?: string;
    endedTimestamp?: string;
  };
  createdBy: string;
}

export interface ApiSimulationDiagnostics {
  simulatedTick: string;
  simulatedClockTime: string;
  numMoving_TRACE: string;
  elapsedTimeInS: string;
  iterationNumber: string;
  numMoving_ZEVO600: string;
  boundingBox: {
    bottom: number;
    top: number;
    left: number;
    right: number;
  };
  cumulativeDistanceDrivenInM: string;
}

export interface SimulationDiagnostics {
  simulatedTick: string;
  numberOfMovingTrace: string;
  iterationNumber: string;
  numberOfMovingZevo: string;
  simulatedClockTime?: string;
  elapsedTime?: string;
  cumulativeDistanceDriven?: string;
}

export interface EstimatedCostConfirmProp extends Entity {
  estimatedCost: number;
}

export interface SimulationValidationMap {
  [key: string]: {
    id: string;
    name: string;
    geography: SimulationGeography;
    fleetSize: FleetSize;
  };
}
