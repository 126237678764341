import { Permission, Profile, Role, User } from '@gm-commercial/profile-model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

import {
  API_VERSION_DEFAULTS,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import {
  ApiResponse,
  BDRequestStatus,
  OperationStatus,
} from '~/common/models/apis/apiResponse.model';
import { Entity } from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import {
  ApiOnboardingState,
  OnboardingState,
} from '~/common/models/onboarding.model';
import {
  addAcceptLanguageHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makePutPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { RootState } from '../../app/rootReducer';
import {
  mapApiResponseToOnboardingState,
  mapApiResponseToProfile,
  mapOnboardingStateToApiResponse,
  mapPermissionResponse,
} from './mappers/profile.mappers';

export enum ProfileRequestType {
  GET_PROFILE = 'Get User Profile',
  GET_PERMISSIONS = 'Get User Permissions',
  GET_ALLOWED_CONTEXTS = 'Get Allowed Contexts',
  GET_ONBOARDING_STATE = 'Get Onboarding State',
  SAVE_ONBOARDING_STATE = 'Save Onboarding State',
}

type ContextParams = Required<Pick<RouteParams, 'organizationsId'>> &
  Pick<RouteParams, 'hubsId' | 'fleetsId'>;

export type ContextItem = Entity & { parentIds?: string[] };

export type AllowedContexts = {
  [orgId: string]: Entity & {
    hubs: {
      [hubId: string]: Entity & {
        fleets: {
          [fleetId: string]: Entity;
        };
      };
    };
  };
};

type ApiOrgContext = Entity & {
  hubs: {
    [hubId: string]: Entity & { fleets: { [fleetId: string]: Entity } };
  };
};

// TODO: combine context type predicates when `/children` returns a recursive model
const isOrgContext =
  (params: ContextParams) =>
  (result: unknown): result is ApiOrgContext => {
    return (
      typeof result === 'object' &&
      (result as ApiOrgContext)?.id === params.organizationsId &&
      !!(result as ApiOrgContext)?.hubs
    );
  };

export interface ProfileState {
  profile?: Profile;
  actingProfile?: Profile;
  operations: { [key in ProfileRequestType]?: OperationStatus };
  allowedContexts?: AllowedContexts;
  effectiveContext?: ContextItem;
  onboardingState?: OnboardingState;
  currentLocale?: string;
  rowsPerPage?: number;
}

const initialState: ProfileState = {
  profile: undefined,
  actingProfile: undefined,
  operations: {},
};

const CONFIG = DEFAULT_API_CONFIG;

export const getProfile = makeThunk(
  'profile/fetchProfile',
  makeGetPayloadCreator<ApiResponse<User>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.default}/me`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiResponseToProfile(response?.result),
    }),
  })
);

export const getOnboardingState = makeThunk(
  'profile/submitOnboardingState',
  makeGetPayloadCreator<ApiResponse<OnboardingState>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.default}/me/onboardingstate`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<OnboardingState>(response)) {
        return {
          ...response,
          result: mapApiResponseToOnboardingState(response.result),
        };
      }
      throw new BDError('Unexpected onboarding state response', {
        data: response,
      });
    },
  })
);

export const saveOnboardingState = makeThunk(
  'assets/updateAsset',
  makePutPayloadCreator<ApiResponse<ApiOnboardingState>, OnboardingState>({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.default}/me/onboardingstate`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({
      requestBody: mapOnboardingStateToApiResponse(payload),
    }),
  })
);

export const getPermissions = makeThunk(
  'profile/fetchPermissions',
  makeGetPayloadCreator<ApiResponse<Permission[]>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/permissions/${API_VERSION_DEFAULTS.default}/me`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    responseAdapter: (response: any) => {
      return {
        ...response,
        result: mapPermissionResponse(response?.result),
      };
    },
  })
);
export const getAllowedContexts = makeThunk(
  'profile/getAllowedContexts',
  makeGetPayloadCreator<ApiResponse<AllowedContexts>, ContextParams>({
    url: (params) =>
      `${globalThis.appConfig.apiBaseUrl}/organizations/${
        API_VERSION_DEFAULTS.default
      }/:organizationsId${
        params.hubsId
          ? `/hubs/:hubsId${params.fleetsId ? '/fleets/:fleetsId' : ''}`
          : ''
      }/children`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (params) => ({ requestParams: { ...params } }),
    responseAdapter: (response: unknown, _, params) => {
      const { organizationsId } = params;

      if (hasApiResult<ApiOrgContext>(response, isOrgContext(params))) {
        return {
          ...response,
          result: {
            [organizationsId]: { ...response.result },
          },
        };
      }

      throw new BDError('Unable to parse contexts', { data: response });
    },
  })
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setEffectiveContext: (
      state,
      action: PayloadAction<ContextItem | undefined>
    ) => {
      state.effectiveContext = action.payload;
    },
    patchProfile: (state, action: PayloadAction<Partial<User>>) => {
      if (state.profile) {
        state.profile = {
          ...state.profile,
          ...action.payload,
        };
      }
      if (action.payload?.userPreferences?.locale) {
        state.currentLocale = action.payload?.userPreferences?.locale;
      }
    },
    setActingProfile: (
      state,
      action: PayloadAction<RouteParams | undefined>
    ) => {
      if (state.profile && action.payload) {
        const { organizationsId, hubsId, fleetsId } = action.payload;
        if (organizationsId) {
          const organization = state.allowedContexts?.[organizationsId];
          if (organization) {
            if (hubsId) {
              const hub = organization.hubs[hubsId];
              if (hub) {
                if (fleetsId) {
                  const fleet = hub.fleets[fleetsId];
                  if (fleet) {
                    state.actingProfile = {
                      ...state.profile,
                      permissionsContextIds: {
                        organizationsId: organization.id,
                        hubsId: hub.id,
                        fleetsId: fleet.id,
                      },
                      organization,
                      hub,
                      fleet,
                      role: Role.FLEET_MANAGER,
                    };
                  }
                } else {
                  state.actingProfile = {
                    ...state.profile,
                    permissionsContextIds: {
                      ...state.profile.permissionsContextIds,
                      organizationsId: organization.id,
                      hubsId: hub.id,
                    },
                    organization,
                    hub,
                    role: Role.HUB_MANAGER,
                  };
                }
              }
            } else {
              state.actingProfile = {
                ...state.profile,
                organization,
                permissionsContextIds: {
                  ...state.profile.permissionsContextIds,
                  organizationsId: organization.id,
                },
                role: Role.ORG_MANAGER,
              };
            }
          }
        }
      } else {
        state.actingProfile = state.profile;
      }
    },
    resetProfile: (state) => {
      state.operations[ProfileRequestType.GET_PROFILE] = {
        status: BDRequestStatus.IDLE,
        errors: [],
      };
      state.operations[ProfileRequestType.GET_PERMISSIONS] = {
        status: BDRequestStatus.IDLE,
        errors: [],
      };
      state.profile = undefined;
    },
    setCurrentLocale: (state, action) => {
      //Some browsers like Edge use the list of all prefered languages instead of just the first one
      state.currentLocale = action.payload.split(',')[0];
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // profile
    builder.addCase(getProfile.pending, (state) => {
      state.operations[ProfileRequestType.GET_PROFILE] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
      state.profile = undefined;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.operations[ProfileRequestType.GET_PROFILE] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      state.profile = action.payload.result;
      if (
        (!state.allowedContexts ||
          !Object.keys(state.allowedContexts).length) &&
        state.profile.role !== Role.SYSTEM_MANAGER
      ) {
        const { organization, hub, fleet } = state.profile;
        state.allowedContexts = organization?.id
          ? {
              [organization.id]: {
                ...organization,
                hubs: hub?.id
                  ? {
                      [hub.id]: {
                        ...hub,
                        fleets: fleet?.id ? { [fleet.id]: { ...fleet } } : {},
                      },
                    }
                  : {},
              },
            }
          : {};
      }
      if (action.payload.result?.userPreferences?.locale) {
        state.currentLocale = action.payload.result?.userPreferences?.locale;
      }
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.operations[ProfileRequestType.GET_PROFILE] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
          },
        ],
      };
    });

    // permissions
    builder.addCase(getPermissions.pending, (state) => {
      state.operations[ProfileRequestType.GET_PERMISSIONS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
      state.profile = {
        ...state.profile,
        permissions: [],
        permissionsContextIds: {
          organizationsId: undefined,
          fleetsId: undefined,
          hubsId: undefined,
        },
      } as Profile;
    });
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      state.operations[ProfileRequestType.GET_PERMISSIONS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      state.profile = {
        ...state.profile,
        ...action.payload.result,
      } as Profile;
    });

    builder.addCase(getPermissions.rejected, (state, action) => {
      state.operations[ProfileRequestType.GET_PERMISSIONS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
          },
        ],
      };
    });

    // Onboarding State
    builder.addCase(getOnboardingState.pending, (state) => {
      state.operations[ProfileRequestType.GET_ONBOARDING_STATE] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getOnboardingState.fulfilled, (state, action) => {
      state.operations[ProfileRequestType.GET_ONBOARDING_STATE] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      state.onboardingState = action.payload.result;
    });
    builder.addCase(getOnboardingState.rejected, (state, action) => {
      state.operations[ProfileRequestType.GET_ONBOARDING_STATE] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
          },
        ],
      };
    });

    builder.addCase(saveOnboardingState.pending, (state) => {
      state.operations[ProfileRequestType.SAVE_ONBOARDING_STATE] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(saveOnboardingState.fulfilled, (state, action) => {
      state.operations[ProfileRequestType.SAVE_ONBOARDING_STATE] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      state.onboardingState = action.meta.arg;
    });
    builder.addCase(saveOnboardingState.rejected, (state, action) => {
      state.operations[ProfileRequestType.SAVE_ONBOARDING_STATE] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
          },
        ],
      };
    });

    // allowed contexts
    builder.addCase(getAllowedContexts.pending, (state) => {
      state.operations[ProfileRequestType.GET_ALLOWED_CONTEXTS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
      state.allowedContexts = undefined;
    });
    builder.addCase(getAllowedContexts.fulfilled, (state, action) => {
      state.operations[ProfileRequestType.GET_ALLOWED_CONTEXTS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      state.allowedContexts = action.payload.result;
    });
    builder.addCase(getAllowedContexts.rejected, (state, action) => {
      state.operations[ProfileRequestType.GET_ALLOWED_CONTEXTS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
          },
        ],
      };
    });

    // reset state when persist store is purged on logout
    builder.addCase(PURGE, (state) => {
      state = initialState;
    });
  },
});

export const {
  setEffectiveContext,
  patchProfile,
  setActingProfile,
  resetProfile,
  setCurrentLocale,
  setRowsPerPage,
} = profileSlice.actions;

export const selectProfileState = (state: RootState): RootState['profile'] =>
  state.profile;

export const profileReducer = profileSlice.reducer;
