import { Address } from '@gm-commercial/location-model';

import { AssetEventAction } from '~/common/models/asset.model';

export const sanitizeExportEvent = (
  exportEvent: Record<string, string>
): Record<string, string> => {
  return Object.entries(exportEvent).reduce(
    (sanitizedEvent, entry) => ({
      ...sanitizedEvent,
      [entry[0]]: entry[1]?.replace(/^\+|^-|^=|^@/g, "'$&"),
    }),
    {}
  );
};

export const formatEventAddress = (address: Address): string => {
  const { address1, city, postalCode, country } = address || {};
  return [address1, city, postalCode, country]
    .filter((part) => !!part)
    .join(', ');
};

export const getExportId = (exportEventId: string): string => {
  const index = exportEventId.indexOf(
    AssetEventAction.POWER_ON || AssetEventAction.POWER_OFF
  );
  return exportEventId.slice(0, index - 1);
};
