import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import MUIButton from '@mui/material/Button';
import { useTheme, Theme } from '@mui/material/styles';
import { ButtonProps } from './Button.model';
import { generateButtonCss } from './Button.styles';

/* Helper function to filter out unsupported DOM props from being passed into the DOM. */
const shouldForwardProp = (prop: any) =>
  !['hoverIcon', 'icon', 'iconPosition'].includes(prop);

/* Component function to generate a MUI Button with custom styling. */
const StyledButton = styled(MUIButton).withConfig({
  shouldForwardProp,
})<ButtonProps>`
  ${({ theme, ...props }) => generateButtonCss(theme, props)}
`;

/* BD Design System Button Component */
const Button: React.FC<ButtonProps> = ({
  children,
  iconPosition = 'left',
  size = 'large',
  variant = 'primary',
  width = 'auto',
  ...props
}) => {
  const theme = useTheme<Theme>();

  /* State variable to handle icon changes during hover states. */
  const [icon, setIcon] = useState<ReactNode>(props.icon);

  /* Remove variant from props as MUI doesn't know what our variants are. */
  const convertedProps = { ...props, variant: undefined };

  /* Helper function to clean up style class name generation */
  const generateButtonClassName = (): string => {
    const iconPositionText = children ? iconPosition : 'only';

    const iconClass = icon ? `icon-${iconPositionText}` : '';
    const variantClass = variant;
    const sizeClass = size;

    return `${props.className} ${variantClass} ${iconClass} ${sizeClass}`;
  };

  return (
    <StyledButton
      {...convertedProps}
      className={generateButtonClassName()}
      endIcon={iconPosition === 'right' ? icon : undefined}
      onMouseEnter={() => {
        if (props.hoverIcon && !props.disabled) setIcon(props.hoverIcon);
      }}
      onMouseLeave={() => {
        if (props.hoverIcon && !props.disabled) setIcon(props.icon);
      }}
      startIcon={iconPosition === 'left' ? icon : undefined}
      theme={theme}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
