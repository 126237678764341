import { cx } from '@emotion/css';
import { NavBarConfig } from '@gm-commercial/navbar-model';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  Typography,
} from '@mui/material';
import isEqual from 'lodash/isEqual';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { SecureWrapper } from '~/common/components';

import CloseDrawerIcon from '../../assets/icons/closeDrawerIcon.svg?react';
import {
  isNavListItem,
  LegacyNavDrawerProps,
} from './config/legacy.navDrawer.config';
import useStyles from './NavDrawer.styles';
import NavDrawerItem from './NavDrawerItem';

type NavDrawerProps = NavBarConfig | LegacyNavDrawerProps;
const CsNavDrawer = ({
  homePath,
  title,
  toggleable,
  items,
  open,
}: NavDrawerProps): JSX.Element => {
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(!!open);
  const [prevItems, setPrevItems] = useState(items);

  const onMenuClick = useCallback(() => {
    setIsOpen((previousValue) => !previousValue);
  }, []);

  // Resets the nav's open state to the configured value when the context changes.
  if (!isEqual(items, prevItems)) {
    setPrevItems(items);
    setIsOpen(!!open);
  }

  return (
    <>
      {!!items.length && (
        <Drawer
          id="nav-drawer-menu"
          variant="permanent"
          className={cx(classes.drawer, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          })}
          classes={{
            paper: cx({
              [classes.drawerOpen]: isOpen,
              [classes.drawerClose]: !isOpen,
            }),
          }}
        >
          <List className={classes.navDrawerList}>
            <Box className={classes.logoContainer}>
              <Box>
                <Link to={homePath || '/'}>
                  <Box
                    className={cx('logo', { isOpen })}
                    data-testid="bd-logo"
                    id="bd-logo"
                  />
                </Link>
                {toggleable && (
                  <IconButton
                    className={cx('menu', { isOpen })}
                    onClick={onMenuClick}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
              </Box>
              <IconButton
                className={cx('toggle', { isOpen })}
                onClick={onMenuClick}
              >
                <CloseDrawerIcon />
              </IconButton>
            </Box>
            {title && (
              <Box
                className={cx(classes.titleContainer, { isOpen })}
                data-testid="nav-title"
              >
                <Typography variant="h3_deprecated">{title}</Typography>
              </Box>
            )}
            {items.map((item, index) => {
              // SecureWrapper is (currently) only used to control visibility for legacy nav links only
              if (isNavListItem(item)) {
                return (
                  <SecureWrapper
                    requiredPermissions={item.permissions}
                    excludedRoles={item.excludedRoles}
                    requiredRoles={item.requiredRoles}
                    key={`${item.label}-${index}`}
                  >
                    <NavDrawerItem
                      key={`menu-item-${item.testId}`}
                      {...item}
                      isNavDrawerOpen={isOpen}
                    />
                  </SecureWrapper>
                );
              }
              return (
                <NavDrawerItem
                  key={`menu-item-${item.testId}`}
                  {...item}
                  isNavDrawerOpen={isOpen}
                />
              );
            })}
            {toggleable && (
              <Box className={cx(classes.drawerToggle, { isOpen })}>
                <Divider />
                <Box>
                  <IconButton
                    onClick={onMenuClick}
                    id={isOpen ? 'nav-bar-close-btn' : 'nav-bar-open-btn'}
                    data-testid={
                      isOpen ? 'nav-bar-close-btn' : 'nav-bar-open-btn'
                    }
                  >
                    <CloseDrawerIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </List>
        </Drawer>
      )}
    </>
  );
};

export default CsNavDrawer;
