import { Component, ReactNode } from 'react';

const isChunkError = (error: Error) =>
  error?.message &&
  (error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed') ||
    error.message.includes('Failed to load module script'));

class RouteErrorBoundary extends Component {
  constructor(props: { hasError: boolean } | Readonly<{ hasError: boolean }>) {
    super(props);
    this.state = { hasChunkError: false };
  }

  static getDerivedStateFromError(error: Error) {
    if (isChunkError(error)) {
      console.warn('Error loading chunk, forcing window reload', error);
      //TODO: Remove this once CDN/Front door cache is in place AND we do not see more ocurrences of this error
      window.location.reload();
    }
    return { hasChunkError: true };
  }

  componentDidCatch(error: Error) {
    if (!isChunkError(error)) {
      throw error;
    }
  }

  render(): ReactNode {
    return this.props.children;
  }
}

export default RouteErrorBoundary;
