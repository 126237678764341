import {
  ActionReducerMapBuilder,
  CaseReducer,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';

import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';
import {
  CSDashboardState,
  DashboardRequestType,
  DashboardSessionConfig,
} from '~/common/models/csDashboard.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';

import { CS_DASHBOARD_ACTIONS } from './csDashboardSlice.actions';
import { INITIAL_CS_DASHBOARD_STATE } from './csDashboardSlice.constants';

const reducerSetDashboardSessionConfig: CaseReducer<
  typeof INITIAL_CS_DASHBOARD_STATE,
  PayloadAction<DashboardSessionConfig>
> = (state, action) => {
  Object.keys(action.payload).forEach((key) => {
    const payload = action.payload || {};
    state.sessionConfigs = merge(
      state.sessionConfigs,
      {
        [key]: payload[key] && {
          ...payload[key],
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
};

const buildGetConnectivityStatusSummaryReducer = (
  builder: ActionReducerMapBuilder<CSDashboardState>
) => {
  builder.addCase(
    CS_DASHBOARD_ACTIONS.getConnectivityStatusSummary.pending,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [sessionId]: {
            connectivityStatusSummary: {},
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
      state.operations[
        DashboardRequestType.GET_DASHBOARD_CONNECTIVITY_STATUS_SUMMARY
      ] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    }
  );
  builder.addCase(
    CS_DASHBOARD_ACTIONS.getConnectivityStatusSummary.fulfilled,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [sessionId]: {
            connectivityStatusSummary: action.payload.result,
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
      state.operations[
        DashboardRequestType.GET_DASHBOARD_CONNECTIVITY_STATUS_SUMMARY
      ] = {
        status: BDRequestStatus.SUCCEEDED,
        errors: [],
      };
    }
  );
  builder.addCase(
    CS_DASHBOARD_ACTIONS.getConnectivityStatusSummary.rejected,
    (state, action) => {
      state.operations[
        DashboardRequestType.GET_DASHBOARD_CONNECTIVITY_STATUS_SUMMARY
      ] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
          },
        ],
      };
    }
  );
};
//GET DIAGNOSTICS SUMMARY
const buildGetDiagnosticsSummaryReducer = (
  builder: ActionReducerMapBuilder<CSDashboardState>
) => {
  builder
    .addCase(
      CS_DASHBOARD_ACTIONS.getDiagnosticsSummary.pending,
      (state, action) => {
        const { sessionId } = action.meta.arg;
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [sessionId]: {
              DiagnosticsSummary: {},
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
        state.operations[
          DashboardRequestType.GET_DASHBOARD_DIAGNOSTICS_SUMMARY
        ] = {
          status: BDRequestStatus.PENDING,
          errors: [],
        };
      }
    )
    .addCase(
      CS_DASHBOARD_ACTIONS.getDiagnosticsSummary.fulfilled,
      (state, action) => {
        const { sessionId } = action.meta.arg;
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [sessionId]: {
              diagnosticsSummary: action.payload.result,
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
        state.operations[
          DashboardRequestType.GET_DASHBOARD_DIAGNOSTICS_SUMMARY
        ] = {
          status: BDRequestStatus.SUCCEEDED,
          errors: [],
        };
      }
    )
    .addCase(
      CS_DASHBOARD_ACTIONS.getDiagnosticsSummary.rejected,
      (state, action) => {
        state.operations[
          DashboardRequestType.GET_DASHBOARD_CONNECTIVITY_STATUS_SUMMARY
        ] = {
          status: BDRequestStatus.FAILED,
          errors: [
            {
              type: BDAppErrorType.API,
              ...(action.payload || (action.error as BDError)),
            },
          ],
        };
      }
    );
};
export const CS_DASHBOARD_REDUCER_BUILDERS = {
  buildGetConnectivityStatusSummaryReducer,
  buildGetDiagnosticsSummaryReducer,
};

export const CS_DASHBOARD_REDUCERS = {
  reducerSetDashboardSessionConfig,
};
