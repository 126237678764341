import { DESIGN_SYSTEM_COLORS } from '@brightdrop/mui-theme-override';
import isEmpty from 'lodash/isEmpty';

import { RouteParams } from '~/common/configs/route.config';
import { AppEnv } from '~/common/constants/common.constant';
import { BDQuery, BDQueryCriteriaType } from '~/common/models/query.model';
import {
  ApiSimulationStatus,
  FleetSize,
  Simulation,
  SimulationFieldType,
  SimulationGeography,
  SimulationRunTime,
  SimulationStatus,
  SimulationValidationMap,
} from '~/common/models/simulation.model';
import { buildQueryTree } from '~/common/utils/query.utils';

import { AllowedContexts } from '../../profile/profileSlice';
import {
  mapApiSimulationStatus,
  mapSimulationFleetSizeNameForDashboard,
  mapSimulationHubNameForDashboard,
} from '../mappers/simulation.mappers';
import {
  MINUTES_IN_DAY,
  TRACE_COST_PER_DAY,
  TRACE_RATIO,
  ZEVO_COST_PER_DAY,
  ZEVO_RATIO,
} from '../simulation.constants';
import { SimulationSession } from '../simulationSlice';

export const SIMULATION_CUSTOM_RENDER_COLORS = {
  status: {
    [SimulationStatus.QUEUED]: DESIGN_SYSTEM_COLORS.warning[600],
    [SimulationStatus.ACTIVE]: DESIGN_SYSTEM_COLORS.success[600],
    [SimulationStatus.COMPLETED]: DESIGN_SYSTEM_COLORS.text.secondary,
    [SimulationStatus.FAILED]: DESIGN_SYSTEM_COLORS.danger[600],
    [SimulationStatus.TERMINATED_BY_USER]: DESIGN_SYSTEM_COLORS.danger[600],
    [SimulationStatus.RETRYING]: DESIGN_SYSTEM_COLORS.warning[600],
    [SimulationStatus.TERMINATION_REQUESTED]: DESIGN_SYSTEM_COLORS.warning[600],
  },
};

export const NORTHEAST_CORRIDOR_DETAILS = {
  hubName: 'NE Corridor',
  fleetName: 'World Record Trip',
};

export const DEFAULT_JOB_TYPE = 'simulation';

export const buildEstimatedCost = (
  runTime?: SimulationRunTime,
  fleetSize?: FleetSize
): number => {
  let estimatedCost = 0;
  if (runTime && fleetSize) {
    const convertedFleetSize = parseInt(fleetSize) || 0;
    const runTimeInDays = (parseInt(runTime) || 0) / MINUTES_IN_DAY;
    const traceTotalCost =
      TRACE_COST_PER_DAY * convertedFleetSize * TRACE_RATIO * runTimeInDays;
    const zevoTotalCost =
      ZEVO_COST_PER_DAY * convertedFleetSize * ZEVO_RATIO * runTimeInDays;
    estimatedCost = traceTotalCost + zevoTotalCost;
  }
  return Math.trunc(estimatedCost);
};

export const buildFleetSizeOptions = ({
  appEnv,
  geography,
  filter,
}: {
  appEnv?: AppEnv;
  geography?: SimulationGeography;
  filter?: boolean;
}): FleetSize[] => {
  if (geography === SimulationGeography.NORTHEAST_CORRIDOR) {
    return [FleetSize.ONE];
  }
  if (filter) {
    return Object.values(FleetSize);
  }
  switch (appEnv) {
    case AppEnv.DEV:
    case AppEnv.PROD:
    case AppEnv.QA:
      return [FleetSize.TEN, FleetSize.ONE_HUNDRED];
    case AppEnv.UAT:
    default:
      return [
        FleetSize.TEN,
        FleetSize.ONE_HUNDRED,
        FleetSize.ONE_THOUSAND,
        FleetSize.TEN_THOUSAND,
      ];
  }
};

export const buildSimulationFleetName = (
  params?: Required<
    Pick<RouteParams, 'organizationsId' | 'hubsId' | 'fleetsId'>
  >,
  allowedContexts?: AllowedContexts
): string | undefined => {
  return (
    params &&
    allowedContexts?.[params.organizationsId]?.hubs?.[params.hubsId]?.fleets?.[
      params.fleetsId
    ].name
  );
};

export const buildSimulationHubId = (
  allowedContexts?: AllowedContexts,
  organizationsId?: string,
  hubName?: string
): string | undefined => {
  let hubId = undefined;
  const hubs = organizationsId && allowedContexts?.[organizationsId]?.hubs;
  if (hubs && hubName) {
    hubId = Object.values(hubs).find((item) => item.name === hubName)?.id;
  }
  return hubId;
};

export const buildSimulationFleetId = (
  fleetName: string,
  allowedContexts?: AllowedContexts,
  organizationsId?: string,
  hubId?: string
): string | undefined => {
  let fleetId = undefined;
  const fleets =
    organizationsId &&
    hubId &&
    allowedContexts?.[organizationsId]?.hubs?.[hubId]?.fleets;
  if (fleets) {
    fleetId = Object.values(fleets).find((item) => item.name === fleetName)?.id;
  }
  return fleetId;
};

export const buildFleetDashboardLinkDetails = (
  geography?: SimulationGeography,
  fleetsize?: FleetSize,
  organizationsId?: string,
  allowedContexts?: AllowedContexts
):
  | Required<Pick<RouteParams, 'organizationsId' | 'hubsId' | 'fleetsId'>>
  | undefined => {
  if (organizationsId && geography && fleetsize) {
    const hubName =
      geography === SimulationGeography.NORTHEAST_CORRIDOR
        ? NORTHEAST_CORRIDOR_DETAILS.hubName
        : mapSimulationHubNameForDashboard(geography);
    const fleetName =
      geography === SimulationGeography.NORTHEAST_CORRIDOR
        ? NORTHEAST_CORRIDOR_DETAILS.fleetName
        : mapSimulationFleetSizeNameForDashboard(fleetsize);
    const fleetKey =
      geography === SimulationGeography.NORTHEAST_CORRIDOR
        ? fleetName
        : [hubName, fleetName].join('-');
    const hubsId = buildSimulationHubId(
      allowedContexts,
      organizationsId,
      hubName
    );
    const fleetsId = buildSimulationFleetId(
      fleetKey,
      allowedContexts,
      organizationsId,
      hubsId
    );
    if (hubsId && fleetsId) {
      return {
        organizationsId,
        hubsId,
        fleetsId,
      };
    }
  }
  return undefined;
};

export const buildSimulationValidationMap = (
  sim?: Simulation[]
): SimulationValidationMap | undefined => {
  const statusMap = [
    SimulationStatus.ACTIVE,
    SimulationStatus.QUEUED,
    SimulationStatus.RETRYING,
  ];
  return (
    sim &&
    sim
      .filter((item) => statusMap.includes(item.status))
      .reduce((accumulator, value) => {
        return {
          ...accumulator,
          [value.geography + value.fleetSize]: {
            id: value.id,
            name: value.name,
            geography: value.geography,
            fleetSize: value.fleetSize,
          },
        };
      }, {} as SimulationValidationMap)
  );
};

export const buildSimulationFilterQuery = (
  type: SimulationFieldType,
  input: Array<string>
): BDQuery | undefined => {
  let criteria = undefined;
  switch (type) {
    case SimulationFieldType.FLEET_SIZE:
    case SimulationFieldType.GEOGRAPHY:
      criteria = {
        qualifier: BDQueryCriteriaType.IN,
        field: type,
        values: input,
      } as BDQuery;
      break;
    case SimulationFieldType.RUN_TIME:
      criteria = {
        qualifier: BDQueryCriteriaType.IN,
        field: type,
        values: input.map((item) => parseInt(item)),
      } as BDQuery;
      break;
    case SimulationFieldType.STATUS: {
      const statuses = input.map((item) => mapApiSimulationStatus(item));
      const selectedOptions = statuses.includes(ApiSimulationStatus.SUCCEEDED)
        ? statuses.concat(ApiSimulationStatus.TERMINATED_BY_TIMEOUT)
        : statuses;
      criteria = {
        qualifier: BDQueryCriteriaType.IN,
        field: type,
        values: selectedOptions,
      } as BDQuery;
      break;
    }
    case SimulationFieldType.JOB_TYPE: {
      criteria = {
        qualifier: BDQueryCriteriaType.IS_EQUAL,
        field: type,
        values: input,
      } as BDQuery;
      break;
    }
  }
  return criteria;
};

export const buildSimulationQuery = (
  config: Partial<SimulationSession> | undefined
): BDQuery | undefined => {
  const simulationStatusQuery =
    config?.simulationStatus && !isEmpty(config.simulationStatus)
      ? buildSimulationFilterQuery(
          SimulationFieldType.STATUS,
          config.simulationStatus
        )
      : undefined;
  const simulationFleetSizeQuery =
    config?.simulationFleetSize && !isEmpty(config.simulationFleetSize)
      ? buildSimulationFilterQuery(
          SimulationFieldType.FLEET_SIZE,
          config.simulationFleetSize
        )
      : undefined;
  const simulationGeographyQuery =
    config?.simulationGeography && !isEmpty(config.simulationGeography)
      ? buildSimulationFilterQuery(
          SimulationFieldType.GEOGRAPHY,
          config.simulationGeography
        )
      : undefined;
  const simulationRunTimeQuery =
    config?.simulationRunTime && !isEmpty(config.simulationRunTime)
      ? buildSimulationFilterQuery(
          SimulationFieldType.RUN_TIME,
          config.simulationRunTime
        )
      : undefined;
  const simulationJobTypeQuery = buildSimulationFilterQuery(
    SimulationFieldType.JOB_TYPE,
    [DEFAULT_JOB_TYPE]
  );
  const filteredQueries = [
    simulationJobTypeQuery,
    simulationStatusQuery,
    simulationFleetSizeQuery,
    simulationGeographyQuery,
    simulationRunTimeQuery,
  ].filter((query) => query !== undefined) as BDQuery[];
  if (filteredQueries.length === 0) {
    return undefined;
  }
  return buildQueryTree(filteredQueries, 0, filteredQueries.length - 1, true);
};
