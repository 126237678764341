import { useFeatureFlags } from '@brightdrop/feature-flags-client';
import { usePermissions, useRole } from '@gm-commercial/profile-context';
import { Role } from '@gm-commercial/profile-model';
import { lazy, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  matchPath,
  Redirect,
  Route,
  RouteProps,
  useLocation,
} from 'react-router-dom';
import { createSelector } from 'reselect';

import { RouteParams } from '~/common/configs/route.config';
import useCustomAuthentication from '~/common/hooks/useCustomAuthentication';
import useSearchParams from '~/common/hooks/useSearchParams';
import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';

import { RootState } from '../../app/rootReducer';
import {
  ProfileRequestType,
  selectProfileState,
} from '../profile/profileSlice';
import {
  selectActingProfileId,
  selectActingProfileStatus,
  selectProfileContextIds,
  selectProfileId,
  selectProfileStatus,
} from '../profile/profileSlice.selectors';

const UserTermsConditions = lazy(
  () => import('../users/termsConditions/UserTermsConditions')
);

interface PrivateRouteProps extends RouteProps {
  authorizedPerms: string[];
  authorizedRoles?: Role[];
  excludedRoles?: Role[];
  actingProfile?: boolean;
  requiredFeatureFlags?: string[];
}

const selectProfileRequestStatus = createSelector(
  (state: RootState) =>
    selectProfileState(state).operations[ProfileRequestType.GET_PROFILE],
  (getProfileRequest) => getProfileRequest?.status
);

const selectProfileParams = createSelector(
  selectProfileId,
  selectProfileStatus,
  selectProfileContextIds,
  (id, status, contextIds) => {
    return { id, status, ...contextIds };
  }
);

const selectActingProfileParams = createSelector(
  selectActingProfileId,
  selectActingProfileStatus,
  selectProfileContextIds,
  (id, status, contextIds) => {
    return { id, status, ...contextIds };
  }
);

const SecureRoute = ({
  actingProfile = true,
  ...props
}: PrivateRouteProps): JSX.Element => {
  const { authorizedPerms, authorizedRoles, excludedRoles, ...routeProps } =
    props;
  const { hasPermission } = usePermissions();
  const { isRoleAuthorized } = useRole(actingProfile);
  const { token } = useCustomAuthentication();
  const location = useLocation<Location>();
  const match = matchPath(location.pathname, routeProps);
  const searchParams = useSearchParams();

  const isAuthorized = useMemo(
    () => hasPermission(authorizedPerms || []),
    [hasPermission, authorizedPerms]
  );
  const roleAllowed = useMemo(
    () => isRoleAuthorized({ authorizedRoles, excludedRoles }),
    [isRoleAuthorized, authorizedRoles, excludedRoles]
  );

  const profileParams = useSelector(
    actingProfile ? selectActingProfileParams : selectProfileParams
  );
  const profileRequestStatus = useSelector(selectProfileRequestStatus);

  const routeParams: RouteParams = useMemo(() => match?.params || {}, [match]);

  const organizationsId = useMemo(
    () =>
      searchParams?.organizationsId
        ? searchParams?.organizationsId
        : routeParams?.organizationsId,
    [routeParams?.organizationsId, searchParams?.organizationsId]
  );

  const hubsId = useMemo(
    () => (searchParams?.hubsId ? searchParams.hubsId : routeParams?.hubsId),
    [routeParams?.hubsId, searchParams?.hubsId]
  );

  const fleetsId = useMemo(
    () =>
      searchParams?.fleetsId ? searchParams.fleetsId : routeParams?.fleetsId,
    [routeParams?.fleetsId, searchParams?.fleetsId]
  );

  const { getFlag } = useFeatureFlags();

  const featureFlagsAllowed = useMemo(() => {
    if (!props.requiredFeatureFlags?.length) {
      return true;
    } else {
      return props.requiredFeatureFlags.some((flagName) => !!getFlag(flagName));
    }
  }, [props.requiredFeatureFlags, getFlag]);

  const hasContextParam = useMemo(
    () => !!organizationsId || !!hubsId || !!fleetsId,
    [fleetsId, hubsId, organizationsId]
  );

  const contextsMatch = useMemo(
    () =>
      !hasContextParam ||
      ((profileParams?.organizationsId === organizationsId ||
        (!profileParams?.organizationsId && !!organizationsId)) &&
        (profileParams?.hubsId === hubsId ||
          (!profileParams?.hubsId && !!hubsId)) &&
        (profileParams?.fleetsId === fleetsId ||
          (!profileParams?.fleetsId && !!fleetsId))),
    [
      fleetsId,
      hasContextParam,
      hubsId,
      organizationsId,
      profileParams?.fleetsId,
      profileParams?.hubsId,
      profileParams?.organizationsId,
    ]
  );

  return (
    <>
      {token &&
      profileParams.status === 'active' &&
      isAuthorized &&
      roleAllowed &&
      contextsMatch &&
      featureFlagsAllowed ? (
        <Route {...routeProps} />
      ) : profileParams.status === 'pending' ? (
        <Route
          {...routeProps}
          path={`/users/${profileParams.id}/terms-and-conditions`}
          component={UserTermsConditions}
        />
      ) : (
        <Redirect
          to={{
            pathname:
              !token ||
              !profileRequestStatus ||
              profileRequestStatus === BDRequestStatus.IDLE
                ? '/'
                : '/unauthorized',
            state: { ...routeProps.location },
          }}
        />
      )}
    </>
  );
};

export default SecureRoute;
