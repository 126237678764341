import {
  BrakePadStatus,
  FuelStatus,
  OilLifeStatus,
} from '~/common/models/asset-report.model';

export const MIN_VALID_FUEL_VALUE = 0;
export const MAX_VALID_FUEL_VALUE = 100;

//Filter modal status label
export const FUEL_FILTER_MODAL_STATUS: {
  [k in FuelStatus]: {
    start?: number;
    end?: number;
    inclusiveStart?: boolean;
    inclusiveEnd?: boolean;
  };
} = {
  [FuelStatus.CRITICAL_FUEL]: {
    end: 15,
  },
  [FuelStatus.LOW_FUEL]: {
    start: 15,
    end: 25,
    inclusiveStart: true,
    inclusiveEnd: true,
  },
  [FuelStatus.MEDIUM_FUEL]: {
    start: 25,
    end: 80,
    inclusiveEnd: true,
  },
  [FuelStatus.HIGH_FUEL]: {
    start: 80,
  },
};

export const OIL_LIFE_FILTER_MODAL_STATUS: {
  [k in OilLifeStatus]: {
    start?: number;
    end?: number;
  };
} = {
  [OilLifeStatus.GOOD]: {
    start: 20,
  },
  [OilLifeStatus.IMPAIRED]: {
    start: 5,
    end: 20,
  },
  [OilLifeStatus.CRITICAL]: {
    end: 5,
  },
};

export const BRAKE_PAD_FILTER_MODAL_STATUS: {
  [k in BrakePadStatus]: {
    start?: number;
    end?: number;
  };
} = {
  [OilLifeStatus.GOOD]: {
    start: 25,
  },
  [OilLifeStatus.IMPAIRED]: {
    start: 15,
    end: 25,
  },
  [OilLifeStatus.CRITICAL]: {
    end: 15,
  },
};
