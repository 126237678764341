import { datadogLogs, StatusType } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { Component } from 'react';

import {
  TelemetryLog,
  TelemetryLogConfig,
  TelemetryLogSeverityLevel,
  TelemetrySessionConfig,
} from '../models/telemetry.model';
import TelemetryProviderContext from './TelemetryProviderContext';

interface TelemetryProviderProps {
  applicationName: string;
  logTrackingConfig: TelemetryLogConfig;
  sessionTackingConfig: TelemetrySessionConfig;
  withLogTracking?: boolean;
  withSessionTracking?: boolean;
  /**
   * Optional callback to execute after provider mounts, regardless of initialization success
   */
  after?: { (): void };
}

interface TelemetryProviderState {
  initialized: boolean;
}

class TelemetryProvider extends Component<
  TelemetryProviderProps,
  TelemetryProviderState
> {
  state: TelemetryProviderState = {
    initialized: false,
  };
  componentDidMount() {
    const { initialized } = this.state;
    if (!initialized) {
      this.initialize();
      this.setState({ initialized: true });
    }

    if (this.props.after) {
      this.props.after();
    }
  }

  initialize() {
    if (this.props.withSessionTracking) {
      datadogRum.init(this.props.sessionTackingConfig);
      datadogRum.startSessionReplayRecording();
    }
    if (this.props.withLogTracking) {
      datadogLogs.init(this.props.logTrackingConfig);
      console.log = this.trackLogEvent(StatusType.info);
      console.error = this.trackLogEvent(StatusType.error);
      console.debug = this.trackLogEvent(StatusType.debug);
      console.warn = this.trackLogEvent(StatusType.warn);
    }
    this.addContext('app', { name: this.props.applicationName });
  }

  addContext(name: string, value: unknown) {
    datadogLogs.setGlobalContextProperty(name, value);
    datadogRum.setGlobalContextProperty(name, value);
  }

  log({ message, logLevel = StatusType.info, args = [] }: TelemetryLog) {
    //Log to Data Dog
    datadogLogs.logger.log(message, args, logLevel);
  }

  trackLogEvent(
    logLevel: TelemetryLogSeverityLevel
  ): (message: unknown, ...args: Record<string, unknown>[]) => void {
    return (message: unknown, ...args: Record<string, unknown>[]) => {
      const traceMessage =
        typeof message === 'string'
          ? (message as string)
          : `${this.props.applicationName} ${logLevel.toUpperCase()} Event`;
      if (args && args.length) {
        this.log({
          message: traceMessage,
          logLevel,
          args,
        });
      } else {
        this.log({
          message: traceMessage,
          logLevel,
        });
      }
    };
  }

  render() {
    const { children } = this.props;
    return (
      <TelemetryProviderContext.Provider
        value={{ addTelemetryContext: this.addContext, log: this.log }}
      >
        {children}
      </TelemetryProviderContext.Provider>
    );
  }
}

export default TelemetryProvider;
