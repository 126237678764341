import { Typography } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { selectActingProfileOrganization } from '~/features/profile/profileSlice.selectors';

const selectProfileOrgName = createSelector(
  selectActingProfileOrganization,
  (org) => org?.name
);

/**
 * Displays acting user context per {@link https://dev.azure.com/BrightDrop/BrightDrop/_workitems/edit/50659 50659}
 *
 * Will be enhanced to support context switching as part of {@link https://dev.azure.com/BrightDrop/BrightDrop/_workitems/edit/51952 51952}
 */
const ContextSwitcher = () => {
  const activeContext = useSelector(selectProfileOrgName);
  return (
    <Typography
      variant={'body_medium'}
      data-testid="context-switcher"
      sx={{ alignSelf: 'center' }}
    >
      {activeContext || ''}
    </Typography>
  );
};
export default ContextSwitcher;
