import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import { DistanceUnit } from '~/common/models/asset.model';
import {
  ApiSimulation,
  ApiSimulationDiagnostics,
  ApiSimulationStatus,
  FleetSize,
  Simulation,
  SimulationAPIRequest,
  SimulationDiagnostics,
  SimulationFormFields,
  SimulationGeography,
  SimulationOperation,
  SimulationRunTime,
  SimulationSpeed,
  SimulationStatus,
} from '~/common/models/simulation.model';

import { convertDistance } from '../../assets/details/utils/assetDetails.utils';

export const mapSimulationOperation = (
  apiStatus?: ApiSimulationStatus
): SimulationOperation =>
  apiStatus === ApiSimulationStatus.ACTIVE ||
  apiStatus === ApiSimulationStatus.RETRYING ||
  apiStatus === ApiSimulationStatus.NOT_STARTED
    ? SimulationOperation.STOP
    : SimulationOperation.CLONE;

export const mapSimulationStatus = (
  apiStatus?: ApiSimulationStatus
): SimulationStatus => {
  switch (apiStatus) {
    case ApiSimulationStatus.ACTIVE:
      return SimulationStatus.ACTIVE;
    case ApiSimulationStatus.RETRYING:
      return SimulationStatus.RETRYING;
    case ApiSimulationStatus.SUCCEEDED:
    case ApiSimulationStatus.TERMINATED_BY_TIMEOUT:
      return SimulationStatus.COMPLETED;
    case ApiSimulationStatus.NOT_STARTED:
      return SimulationStatus.QUEUED;
    case ApiSimulationStatus.FAILED:
      return SimulationStatus.FAILED;
    case ApiSimulationStatus.TERMINATED_BY_USER:
      return SimulationStatus.TERMINATED_BY_USER;
    case ApiSimulationStatus.TERMINATION_REQUESTED:
      return SimulationStatus.TERMINATION_REQUESTED;
    default:
      return DEFAULT_EMPTY_VALUE as SimulationStatus;
  }
};

export const mapSimulationJobApiResponseToSimulation = (
  apiSimulation: ApiSimulation
): Simulation => {
  return {
    id: apiSimulation.jobId,
    name: apiSimulation?.parameters?.name || DEFAULT_EMPTY_VALUE,
    fleetSize: apiSimulation?.parameters?.size || DEFAULT_EMPTY_VALUE,
    geography: apiSimulation?.parameters?.geography || DEFAULT_EMPTY_VALUE,
    syntheticTelemetry: apiSimulation?.parameters?.synthetic,
    speed: apiSimulation?.parameters?.speed || DEFAULT_EMPTY_VALUE,
    startTime: apiSimulation?.status?.startedTimestamp,
    runTime:
      (apiSimulation?.runtime?.preferredDurationInS.toString() as SimulationRunTime) ||
      DEFAULT_EMPTY_VALUE,
    endTime: apiSimulation?.status?.endedTimestamp,
    requestTime: apiSimulation?.createdDate,
    operation: mapSimulationOperation(apiSimulation?.status?.status),
    status: mapSimulationStatus(apiSimulation?.status?.status),
    organizationsId: apiSimulation?.organizationId,
    userId: apiSimulation?.createdBy,
    diagnostics: mapSimulationDiagnostics(apiSimulation?.status?.diagnostics),
  };
};

export const mapCreateSimulationRequest = (
  form: SimulationFormFields
): SimulationAPIRequest => {
  return {
    jobType: 'simulation',
    parameters: {
      name: form.name,
      geography: form.geography,
      size: form.fleetSize,
      speed: SimulationSpeed.FOLLOW_CLOCK,
      synthetic: form.synthetic,
      preferredDurationInS: parseInt(form.simRunTime),
    },
    runtime: {
      startAfter: form.startTime,
      preferredDurationInS: parseInt(form.simRunTime),
    },
  };
};

export const mapSimulationDiagnostics = (
  diagnostics?: ApiSimulationDiagnostics
): SimulationDiagnostics => {
  return {
    simulatedTick: diagnostics?.simulatedTick || DEFAULT_EMPTY_VALUE,
    simulatedClockTime: diagnostics?.simulatedClockTime,
    numberOfMovingTrace: diagnostics?.numMoving_TRACE || DEFAULT_EMPTY_VALUE,
    elapsedTime:
      diagnostics?.elapsedTimeInS &&
      (parseInt(diagnostics.elapsedTimeInS) / 60).toFixed(),
    iterationNumber: diagnostics?.iterationNumber || DEFAULT_EMPTY_VALUE,
    numberOfMovingZevo: diagnostics?.numMoving_ZEVO600 || DEFAULT_EMPTY_VALUE,
    cumulativeDistanceDriven:
      diagnostics?.cumulativeDistanceDrivenInM &&
      convertDistance(
        parseFloat(diagnostics.cumulativeDistanceDrivenInM) / 1000,
        DistanceUnit.KM,
        DistanceUnit.MI
      ).toString(),
  };
};

export const mapApiSimulationStatus = (
  apiStatus?: string
): ApiSimulationStatus => {
  switch (apiStatus) {
    case SimulationStatus.ACTIVE:
      return ApiSimulationStatus.ACTIVE;
    case SimulationStatus.RETRYING:
      return ApiSimulationStatus.RETRYING;
    case SimulationStatus.COMPLETED:
      return ApiSimulationStatus.SUCCEEDED;
    case SimulationStatus.QUEUED:
      return ApiSimulationStatus.NOT_STARTED;
    case SimulationStatus.FAILED:
      return ApiSimulationStatus.FAILED;
    case SimulationStatus.TERMINATED_BY_USER:
      return ApiSimulationStatus.TERMINATED_BY_USER;
    case SimulationStatus.TERMINATION_REQUESTED:
      return ApiSimulationStatus.TERMINATION_REQUESTED;
    default:
      return DEFAULT_EMPTY_VALUE as ApiSimulationStatus;
  }
};

export const mapSimulationHubNameForDashboard = (
  geography?: SimulationGeography
): string => {
  const LA_SIMULATION_HUB = 'LA';
  switch (geography) {
    case SimulationGeography.ATLANTA:
    case SimulationGeography.DALLAS:
    case SimulationGeography.DENVER:
    case SimulationGeography.DETROIT:
    case SimulationGeography.PHILADELPHIA:
      return geography.slice(0, 3).toLocaleUpperCase();
    case SimulationGeography.LOS_ANGELES:
      return LA_SIMULATION_HUB;
    default:
      return DEFAULT_EMPTY_VALUE;
  }
};

export const mapSimulationFleetSizeNameForDashboard = (
  fleetSize?: FleetSize
): string => {
  const TEN_THOUSAND_SIZE = '10k';
  const ONE_THOUSAND = '1k';
  switch (fleetSize) {
    case FleetSize.TEN:
    case FleetSize.ONE_HUNDRED:
      return fleetSize;
    case FleetSize.ONE_THOUSAND:
      return ONE_THOUSAND;
    case FleetSize.TEN_THOUSAND:
      return TEN_THOUSAND_SIZE;
    default:
      return DEFAULT_EMPTY_VALUE;
  }
};
