import {
  BaseAction,
  OperationAction,
  Permission,
  WorkPlanOperationAction,
} from './permission.model';

type OrgAction = Pick<typeof BaseAction, 'Create' | 'Read' | 'Update'>;
export const OrganizationPermission: {
  [k in Uppercase<keyof OrgAction>]: Permission &
    `Organization.${keyof OrgAction}`;
} = {
  CREATE: 'Organization.Create',
  READ: 'Organization.Read',
  UPDATE: 'Organization.Update',
};

type ProfileAction = Pick<typeof BaseAction, 'Read' | 'Update' | 'Invite'>;
export const ProfilePermission: {
  [k in Uppercase<keyof ProfileAction>]: Permission &
    `Profile.${keyof ProfileAction}`;
} = {
  READ: 'Profile.Read',
  UPDATE: 'Profile.Update',
  INVITE: 'Profile.Invite',
};

type HubAction = Pick<typeof BaseAction, 'Create' | 'Read' | 'Update'>;
export const HubPermission: {
  [k in Uppercase<keyof HubAction>]: Permission & `Hub.${keyof HubAction}`;
} = {
  CREATE: 'Hub.Create',
  READ: 'Hub.Read',
  UPDATE: 'Hub.Update',
};

type FleetAction = Pick<typeof BaseAction, 'Create' | 'Read' | 'Update'>;
export const FleetPermission: {
  [k in Uppercase<keyof FleetAction>]: Permission &
    `Fleet.${keyof FleetAction}`;
} = {
  CREATE: 'Fleet.Create',
  READ: 'Fleet.Read',
  UPDATE: 'Fleet.Update',
};

type AssetAction = Pick<typeof BaseAction, 'Create' | 'Read' | 'Update'> &
  Pick<typeof OperationAction, 'PerformOperation' | 'ModifyProtectedValues'>;
export const AssetPermission: {
  [k in Uppercase<keyof AssetAction>]: Permission &
    `Asset.${keyof AssetAction}`;
} = {
  CREATE: 'Asset.Create',
  READ: 'Asset.Read',
  UPDATE: 'Asset.Update',
  PERFORMOPERATION: 'Asset.PerformOperation',
  MODIFYPROTECTEDVALUES: 'Asset.ModifyProtectedValues',
};

type WorkPlanAction = Pick<typeof BaseAction, 'Create' | 'Read' | 'Delete'> &
  Pick<typeof WorkPlanOperationAction, 'AssignOperator' | 'SetStatus'>;
export const WorkPlanPermission: {
  [k in Uppercase<keyof WorkPlanAction>]: Permission &
    `WorkPlan.${keyof WorkPlanAction}`;
} = {
  CREATE: 'WorkPlan.Create',
  READ: 'WorkPlan.Read',
  DELETE: 'WorkPlan.Delete',
  ASSIGNOPERATOR: 'WorkPlan.AssignOperator',
  SETSTATUS: 'WorkPlan.SetStatus',
};

//TODO: 12822 update when integration ready - This will give you an unauthorized page if set to Alert
type AlertAction = Pick<typeof BaseAction, 'Read' | 'Update'>;
export const AlertPermission: {
  [k in Uppercase<keyof AlertAction>]: Permission &
    `Asset.${keyof AlertAction}`;
} = {
  READ: 'Asset.Read',
  UPDATE: 'Asset.Update',
};

type IntegrationAction = Pick<
  typeof BaseAction,
  'Create' | 'Read' | 'Update' | 'Delete'
>;
export const IntegrationPermission: {
  [k in Uppercase<keyof IntegrationAction>]: Permission &
    `Integration.${keyof IntegrationAction}`;
} = {
  CREATE: 'Integration.Create',
  READ: 'Integration.Read',
  UPDATE: 'Integration.Update',
  DELETE: 'Integration.Delete',
};

type JobAction = Pick<typeof BaseAction, 'Create' | 'Read' | 'Update'>;
export const JobPermission: {
  [k in Uppercase<keyof JobAction>]: Permission & `Job.${keyof JobAction}`;
} = {
  CREATE: 'Job.Create',
  READ: 'Job.Read',
  UPDATE: 'Job.Update',
};

type InsightAction = Pick<
  typeof BaseAction,
  'Read' | 'Create' | 'Update' | 'Delete'
>;
export const InsightPermission: {
  [k in Uppercase<keyof InsightAction>]: Permission &
    `Insight.${keyof InsightAction}`;
} = {
  CREATE: 'Insight.Create',
  READ: 'Insight.Read',
  UPDATE: 'Insight.Update',
  DELETE: 'Insight.Delete',
};

type InspectionAction = Pick<typeof BaseAction, 'Read'>;
export const InspectionPermission: {
  [k in Uppercase<keyof InspectionAction>]: Permission &
    `Inspection.${keyof InspectionAction}`;
} = {
  READ: 'Inspection.Read',
};
type VehicleOrderAction = Pick<
  typeof BaseAction,
  'Create' | 'Read' | 'Update' | 'Delete'
>;
export const VehicleOrderPermission: {
  [k in Uppercase<keyof VehicleOrderAction>]: Permission &
    `Order.${keyof VehicleOrderAction}`;
} = {
  CREATE: 'Order.Create',
  READ: 'Order.Read',
  UPDATE: 'Order.Update',
  DELETE: 'Order.Delete',
};
