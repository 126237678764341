import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';
import { PURGE } from 'redux-persist';

import {
  API_VERSION_DEFAULTS,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import {
  ApiResponse,
  BDRequestStatus,
  BDRequestType,
  OperationStatus,
} from '~/common/models/apis/apiResponse.model';
import {
  DetailedPagedResult,
  ListViewSession,
  PaginationInfo,
  SeverityLevel,
} from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import {
  ApiSimulation,
  FleetSize,
  Simulation,
  SimulationAPIRequest,
  SimulationFormFields,
  SimulationGeography,
  SimulationOperation,
  SimulationRunTime,
  SimulationStatus,
} from '~/common/models/simulation.model';
import {
  addAcceptLanguageHeader,
  addOrganizationIdHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makePostPayloadCreator,
  makePutPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { RootState } from '../../app/rootReducer';
import { mapSimulationJobApiResponseToSimulation } from './mappers/simulation.mappers';
import { buildSimulationQuery } from './utils/simulation.utils';

export interface SimulationSession extends ListViewSession {
  simulationStatus: SimulationStatus[];
  simulationFleetSize: FleetSize[];
  simulationGeography: SimulationGeography[];
  simulationRunTime: SimulationRunTime[];
}

export enum SimulationViewType {
  MAIN = 'listView',
}

export enum SimulationRequestType {
  UPDATE_SIMULATION_STATUS = 'Update Simulation Status',
}

export interface SimulationSessionConfigType {
  [SimulationViewType.MAIN]?: Partial<SimulationSession>;
}

const simulationAdapter = createEntityAdapter<Simulation>({
  selectId: (simulation: Simulation) => simulation.id,
});

interface SimulationState {
  sessionConfigs: {
    [k in SimulationViewType]?: SimulationSessionConfigType[k];
  };
  operations: {
    [key in BDRequestType | SimulationRequestType]?: OperationStatus;
  };
  selectedSimulationId?: string;
  selectedSimulationFormFields?: SimulationFormFields;
  selectedSimulationOperation?: SimulationOperation;
}

type getSimulationsParams = Pick<RouteParams, 'organizationsId'> &
  PaginationInfo;

type createSimulationParams = Pick<RouteParams, 'organizationsId'> &
  SimulationAPIRequest;

type updateSimulationStatusParams = Pick<RouteParams, 'organizationsId'> & {
  jobId: string;
  action: string;
};

const initialState = simulationAdapter.getInitialState<SimulationState>({
  sessionConfigs: {},
  operations: {},
});

const CONFIG = DEFAULT_API_CONFIG;

const DEFAULT_SEARCH_SIMULAITONS_SIZE = 200;

export const searchSimulations = makeThunk(
  'simulation/searchSimulations',
  makePostPayloadCreator<
    ApiResponse<DetailedPagedResult<Simulation>>,
    getSimulationsParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/jobs/${API_VERSION_DEFAULTS.default}/searches`,
    axiosOptions: ({ organizationsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(CONFIG, organizationsId),
        state.profile.currentLocale
      ),
    argAdapter: ({ page }, state) => {
      const sessionConfig =
        state.simulation.sessionConfigs?.[SimulationViewType.MAIN];
      const query = buildSimulationQuery(sessionConfig);
      return {
        requestBody: {
          page,
          size: DEFAULT_SEARCH_SIMULAITONS_SIZE,
          query,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (hasApiResult<DetailedPagedResult<ApiSimulation>>(response)) {
        const errors = new Array<BDError>();
        const { content, total_items } = response.result;
        return {
          ...response,
          result: {
            total_items,
            content: content?.length
              ? content.reduce((all, item) => {
                  try {
                    return [
                      ...all,
                      mapSimulationJobApiResponseToSimulation(item),
                    ];
                  } catch (e) {
                    errors.push({
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map simulation job API response',
                      data: item,
                    } as BDError);
                  }
                  return all;
                }, new Array<Simulation>())
              : [],
          },
        };
      }
      throw new BDError('Unexpected response', { data: response });
    },
  })
);

export const createSimulation = makeThunk(
  'simulation/createSimulation',
  makePostPayloadCreator<
    ApiResponse<{ jobId: string }>,
    createSimulationParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/jobs/${API_VERSION_DEFAULTS.default}`,
    axiosOptions: ({ organizationsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(CONFIG, organizationsId),
        state.profile.currentLocale
      ),
    argAdapter: ({ jobType, parameters, runtime }) => ({
      requestBody: {
        jobType,
        parameters,
        runtime,
      },
    }),
  })
);

export const updateSimulationStaus = makeThunk(
  'simulation/updateSimulationStatus',
  makePutPayloadCreator<ApiResponse, updateSimulationStatusParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/jobs/${API_VERSION_DEFAULTS.default}/:jobId/status`,
    axiosOptions: ({ organizationsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(CONFIG, organizationsId),
        state.profile.currentLocale
      ),
    argAdapter: ({ jobId, action }) => ({
      requestParams: { jobId },
      requestBody: { action },
    }),
  })
);

export const simuationSlice = createSlice({
  name: 'simulation',
  initialState,
  reducers: {
    setSimulationSessionConfig: (
      state,
      action: PayloadAction<SimulationSessionConfigType>
    ) => {
      Object.keys(action.payload).forEach((key) => {
        const scope = key as SimulationViewType;
        const payload = action.payload[scope] || {};
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [scope]: payload,
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      });
    },
    clearSimulationFilterSessionConfig: (
      state,
      action: PayloadAction<{ viewType: SimulationViewType }>
    ) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [action.payload.viewType]: {
            simulationStatus: [],
            simulationFleetSize: [],
            simulationGeography: [],
            simulationRunTime: [],
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    },
    setSelectedSimulationId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectedSimulationId = action.payload;
    },
    setSelectedSimulationFormFields: (
      state,
      action: PayloadAction<SimulationFormFields | undefined>
    ) => {
      state.selectedSimulationFormFields = action.payload;
    },
    setSelectedSimulationOperation: (
      state,
      action: PayloadAction<SimulationOperation | undefined>
    ) => {
      state.selectedSimulationOperation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchSimulations.pending, (state) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(searchSimulations.fulfilled, (state, action) => {
      const { page, rowsPerPage } = action.meta.arg;
      simulationAdapter.setAll(state, action.payload.result.content);
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [SimulationViewType.MAIN]: {
            page,
            rowsPerPage,
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
    builder.addCase(searchSimulations.rejected, (state, action) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    });
    builder.addCase(createSimulation.pending, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(createSimulation.fulfilled, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(createSimulation.rejected, (state, action) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    });
    builder.addCase(updateSimulationStaus.pending, (state) => {
      state.operations[SimulationRequestType.UPDATE_SIMULATION_STATUS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(updateSimulationStaus.fulfilled, (state) => {
      state.operations[SimulationRequestType.UPDATE_SIMULATION_STATUS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(updateSimulationStaus.rejected, (state, action) => {
      state.operations[SimulationRequestType.UPDATE_SIMULATION_STATUS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    });
    builder.addCase(PURGE, (state) => {
      state = initialState;
    });
  },
});
export const {
  setSimulationSessionConfig,
  clearSimulationFilterSessionConfig,
  setSelectedSimulationId,
  setSelectedSimulationFormFields,
  setSelectedSimulationOperation,
} = simuationSlice.actions;

export const {
  selectAll: selectAllSimulations,
  selectById: selectSimulationById,
  selectIds: selectSimulationIds,
} = simulationAdapter.getSelectors<RootState>(
  (state: RootState) => state.simulation
);

export const simulationReducer = simuationSlice.reducer;
