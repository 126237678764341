import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import { Asset, AssetTrip } from '~/common/models/asset.model';
import { format } from '~/common/utils/date-time.utils';

import {
  formatEventAddress,
  getExportId,
  sanitizeExportEvent,
} from '../../assets/details/utils/assetExport.utils';
import { TRIP_LOGGER_MESSAGES } from '../TripLoggerView.messages';

export enum TripType {
  BUSINESS = 'business',
  PERSONAL = 'personal',
}

export const getTripLoggerExportConfig = ({
  labels,
  currentLocale,
  dateTimeFormat,
  timeZone,
  asset,
  trips,
}: {
  labels: typeof TRIP_LOGGER_MESSAGES;
  dateTimeFormat: string;
  timeZone: string;
  currentLocale?: string;
  asset?: Asset;
  trips?: AssetTrip[];
}): {
  headers: { label: string; key: string }[];
  details?: Record<string, string>[];
} => ({
  headers: [
    {
      label: labels.export?.['asset:name'].toLocaleUpperCase(),
      key: 'name',
    },
    {
      label: labels.export?.['asset:detail.tabs.startTime'].toLocaleUpperCase(),
      key: 'start_date_time',
    },
    {
      label: labels.export?.['common:address'].toLocaleUpperCase(),
      key: 'start_address',
    },
    {
      label: labels.export?.['asset:detail.tabs.endTime'].toLocaleUpperCase(),
      key: 'end_date_time',
    },
    {
      label: labels.export?.['common:address'].toLocaleUpperCase(),
      key: 'end_address',
    },
    {
      label:
        labels.export?.['tripLogger:exportLabels.tripType'].toLocaleUpperCase(),
      key: 'trip_type',
    },
  ],
  // returns each individual trip details
  details: trips?.map((trip: AssetTrip) => {
    // Goes through each trip and returns the details to be displayed
    return sanitizeExportEvent({
      // retrieves tripId from the form: 'tripId:POWER_ON:timestamp'
      id: getExportId(trip.id) || DEFAULT_EMPTY_VALUE,
      name: asset?.name || DEFAULT_EMPTY_VALUE,
      start_date_time: format(
        new Date(trip.start.deviceTimestamp),
        dateTimeFormat,
        currentLocale,
        timeZone
      ),
      // returns the address from the start object that holds coords and address
      start_address: trip.start.value.address
        ? formatEventAddress(trip.start.value.address)
        : labels.eventLabels?.['common:notAvailable'],
      end_date_time: format(
        new Date(trip.end.deviceTimestamp),
        dateTimeFormat,
        currentLocale,
        timeZone
      ),
      end_address: trip.end.value.address
        ? formatEventAddress(trip.end.value.address)
        : labels.eventLabels?.['common:notAvailable'],
      // returns trip type of either business or personal
      trip_type: trip.tripType
        ? labels.eventLabels?.[
            `tripLogger:exportValues.tripType.${trip.tripType}`
          ]
        : labels.eventLabels?.['common:notAvailable'],
    });
  }),
});
