import { FilterOption, FilterOptionConfig } from '@brightdrop/bd-ui';

import { BDFilterModalOptionConfig } from '~/common/components/BDFilter/BDFilterModal';
import { AssetServiceStatus } from '~/common/models/asset.model';
import {
  BatteryChargingStatus,
  BatterySocStatus,
  BrakePadStatus,
  FuelStatus,
  LastCommunicatedStatus,
  OilLifeStatus,
  PowerType,
  ReadyStatus,
  ReportDiagnosticState,
  SafetyCompliance,
} from '~/common/models/asset-report.model';
import { ConnectivityStatusType } from '~/common/models/common.model';
import { getRangeLabel } from '~/common/utils/battery.utils';

import { AssetListFilterCriteria } from '../../assetsSlice';
import { ASSET_LIST_REPORT_FILTER_MESSAGES } from '../AssetListReportViewFilterModal.messages';
import {
  BRAKE_PAD_FILTER_MODAL_STATUS,
  FUEL_FILTER_MODAL_STATUS,
  OIL_LIFE_FILTER_MODAL_STATUS,
} from '../AssetReportView.constant';
import { getDiagnosticModalLabel } from '../utils/report.utils';

const diagnosticsHealthFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
) =>
  ({
    title: filterLabels['asset:tableColumnLabels.diagnosticsHealth'],
    id: 'diagnostics-health',
    type: 'diagnosticsHealthStatus',
    options: getDiagnosticsHealthConfig(valueLabels),
  } as FilterOptionConfig<ReportDiagnosticState, AssetListFilterCriteria>);
const availabilityFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
) =>
  ({
    title: filterLabels['asset:vehicle.details.availability'],
    id: 'availability',
    type: 'availabilityStatus',
    options: getAvailabilityConfig(valueLabels),
  } as FilterOptionConfig<AssetServiceStatus, AssetListFilterCriteria>);
const washerFluidFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
) =>
  ({
    title: filterLabels['asset:tableColumnLabels.washerFluidStatus'],
    id: 'washer-fluid-status',
    type: 'washerFluidStatus',
    options: getAssetFluidConfig(valueLabels),
  } as FilterOptionConfig<ReportDiagnosticState, AssetListFilterCriteria>);
const batterySocFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels
) =>
  ({
    title: filterLabels['asset:tableColumnLabels.batterySocStatus'],
    id: 'battery-soc-status',
    type: 'batterySocStatus',
    options: getBatterySocConfig(),
  } as FilterOptionConfig<BatterySocStatus, AssetListFilterCriteria>);

const batteryChargingFilter = (
  options: BatteryChargingStatus[],
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
) =>
  ({
    title: filterLabels['asset:tableColumnLabels.batteryChargingStatus'],
    id: 'battery-charging-status',
    type: 'batteryChargingStatus',
    options: getChargingConfig(options, valueLabels),
  } as FilterOptionConfig<BatteryChargingStatus, AssetListFilterCriteria>);

const getPowerTypeFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
) =>
  ({
    title: filterLabels['asset:power.powerType'],
    id: 'power-type',
    type: 'powerType',
    options: getPowerTypeFilterConfig(valueLabels),
  } as FilterOptionConfig<PowerType, AssetListFilterCriteria>);

const brakeFluidFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
) =>
  ({
    title: filterLabels['asset:tableColumnLabels.brakeFluidStatus'],
    id: 'brake-fluid-status',
    type: 'brakeFluidStatus',
    options: getAssetFluidConfig(valueLabels),
  } as FilterOptionConfig<ReportDiagnosticState, AssetListFilterCriteria>);

const tireStatusFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
) =>
  ({
    title: filterLabels['asset:tableColumnLabels.tireStatus'],
    id: 'tire-status',
    type: 'tireStatus',
    options: getTireConfig(valueLabels),
  } as FilterOptionConfig<ReportDiagnosticState, AssetListFilterCriteria>);

const connectivityStatusFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
) =>
  ({
    title: filterLabels['common:lastCommunicated'],
    id: 'lastCommunicated-status',
    type: 'lastCommunicatedStatus',
    options: getConnectivityConfig(valueLabels),
  } as FilterOptionConfig<LastCommunicatedStatus, AssetListFilterCriteria>);

const fuelFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels
) =>
  ({
    title: filterLabels['asset:fuel'],
    id: 'fuel-status',
    type: 'fuelStatus',
    options: getFuelConfig(),
  } as FilterOptionConfig<FuelStatus, AssetListFilterCriteria>);

const getCommonFilters = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels,
  hideAvailabilityFilter = false
): BDFilterModalOptionConfig<AssetListFilterCriteria>[] => {
  const filters = new Array<
    BDFilterModalOptionConfig<AssetListFilterCriteria>
  >();
  if (!hideAvailabilityFilter) {
    filters.push(availabilityFilter(filterLabels, valueLabels));
  }
  filters.push(connectivityStatusFilter(filterLabels, valueLabels));
  return filters;
};

const faultStatusFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
) =>
  ({
    title: filterLabels['asset:tableColumnLabels.faults'],
    id: 'fault-status',
    type: 'faultStatus',
    options: getFaultConfig(valueLabels),
  } as FilterOptionConfig<ReportDiagnosticState, AssetListFilterCriteria>);

export const AVAILABLE_OIL_LIFE_OPTIONS = [
  OilLifeStatus.GOOD,
  OilLifeStatus.IMPAIRED,
  OilLifeStatus.CRITICAL,
];
export const getOilLifeConfig = (): FilterOption<OilLifeStatus>[] =>
  AVAILABLE_OIL_LIFE_OPTIONS.map((oilLifeStatus) => {
    return {
      label: getDiagnosticModalLabel(
        OIL_LIFE_FILTER_MODAL_STATUS,
        oilLifeStatus
      ),
      value: oilLifeStatus,
    };
  });
const oilLifeFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels
) =>
  ({
    title: filterLabels['asset:oilLife'],
    id: 'oilLife-status',
    type: 'oilLifeStatus',
    options: getOilLifeConfig(),
  } as FilterOptionConfig<OilLifeStatus, AssetListFilterCriteria>);

export const AVAILABLE_BRAKE_PAD_OPTIONS = [
  BrakePadStatus.GOOD,
  BrakePadStatus.IMPAIRED,
  BrakePadStatus.CRITICAL,
];
export const getBrakePadConfig = (): FilterOption<BrakePadStatus>[] =>
  AVAILABLE_BRAKE_PAD_OPTIONS.map((BrakePadStatus) => {
    return {
      label: getDiagnosticModalLabel(
        BRAKE_PAD_FILTER_MODAL_STATUS,
        BrakePadStatus
      ),
      value: BrakePadStatus,
    };
  });
const brakePadFrontFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels
) =>
  ({
    title: filterLabels['asset:brakePadFront'],
    id: 'brakePadFront-status',
    type: 'brakePadFrontStatus',
    options: getBrakePadConfig(),
  } as FilterOptionConfig<BrakePadStatus, AssetListFilterCriteria>);

const brakePadRearFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels
) =>
  ({
    title: filterLabels['asset:brakePadRear'],
    id: 'brakePadRear-status',
    type: 'brakePadRearStatus',
    options: getBrakePadConfig(),
  } as FilterOptionConfig<BrakePadStatus, AssetListFilterCriteria>);

const AVAILABLE_ENGINE_AIR_FILTER_OPTIONS = [
  ReportDiagnosticState.GOOD,
  ReportDiagnosticState.IMPAIRED,
  ReportDiagnosticState.CRITICAL,
];
const getEngineAirFilterConfig = (
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
): FilterOption<ReportDiagnosticState>[] =>
  AVAILABLE_ENGINE_AIR_FILTER_OPTIONS.map((option) => {
    return {
      label:
        valueLabels[
          `asset:filterModalLabels.engineAirFilter.${option}` as keyof typeof valueLabels
        ],
      value: option,
    };
  });
const engineAirFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
) =>
  ({
    title: filterLabels['asset:vehicle.engineAirFilter'],
    id: 'oengineAirFilter-status',
    type: 'engineAirFilterStatus',
    options: getEngineAirFilterConfig(valueLabels),
  } as FilterOptionConfig<ReportDiagnosticState, AssetListFilterCriteria>);

const AVAILABLE_CONNECTIVITY_BOARDING_STATUS_OPTIONS = [
  ConnectivityStatusType.ONBOARDED,
  ConnectivityStatusType.PENDING_ONBOARDING,
  ConnectivityStatusType.PENDING_CONNECTIVITY,
  ConnectivityStatusType.CONNECTED,
];
const getConnectivityBoardingStatusConfig = (
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
): FilterOption<ConnectivityStatusType>[] =>
  AVAILABLE_CONNECTIVITY_BOARDING_STATUS_OPTIONS.map((status) => {
    return {
      label:
        valueLabels[
          `asset:filterModalLabels.connectivityBoardingStatus.${status}` as keyof typeof valueLabels
        ],
      value: status,
    };
  });
const connectivityBoardingStatusFilter = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
) =>
  ({
    title: filterLabels['common:status'],
    id: 'connectivity-boarding-status',
    type: 'connectivityBoardingStatus',
    options: getConnectivityBoardingStatusConfig(valueLabels),
  } as FilterOptionConfig<ConnectivityStatusType, AssetListFilterCriteria>);

export const getReadinessFilterModalConfig = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels,
  hideAvailabilityFilter: boolean
): BDFilterModalOptionConfig<AssetListFilterCriteria>[] => {
  return [
    connectivityBoardingStatusFilter(filterLabels, valueLabels),
    diagnosticsHealthFilter(filterLabels, valueLabels),
    ...getCommonFilters(filterLabels, valueLabels, hideAvailabilityFilter),
    fuelFilter(filterLabels),
    batterySocFilter(filterLabels),
    tireStatusFilter(filterLabels, valueLabels),
    engineAirFilter(filterLabels, valueLabels),
    washerFluidFilter(filterLabels, valueLabels),
    brakeFluidFilter(filterLabels, valueLabels),
    brakePadFrontFilter(filterLabels),
    brakePadRearFilter(filterLabels),
    faultStatusFilter(filterLabels, valueLabels),
    oilLifeFilter(filterLabels),
  ];
};

export const getFluidsFilterModalConfig = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels,
  hideAvailabilityFilter: boolean
): BDFilterModalOptionConfig<AssetListFilterCriteria>[] => {
  return [
    ...getCommonFilters(filterLabels, valueLabels, hideAvailabilityFilter),
    washerFluidFilter(filterLabels, valueLabels),
    brakeFluidFilter(filterLabels, valueLabels),
    oilLifeFilter(filterLabels),
  ];
};

export const getBatteryFilterModalConfig = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels,
  hideAvailabilityFilter: boolean,
  isChargeStatusFlagEnabled: boolean
): BDFilterModalOptionConfig<AssetListFilterCriteria>[] => {
  if (isChargeStatusFlagEnabled) {
    const typeFilter = new Array<
      BDFilterModalOptionConfig<AssetListFilterCriteria>
    >();

    return typeFilter.concat([
      connectivityStatusFilter(filterLabels, valueLabels),
      batterySocFilter(filterLabels),
      batteryChargingFilter(
        BATTERY_READINESS_CHARGING_FILTER_OPTIONS,
        filterLabels,
        valueLabels
      ),
      getPowerTypeFilter(filterLabels, valueLabels),
    ]);
  } else {
    return [
      ...getCommonFilters(filterLabels, valueLabels, hideAvailabilityFilter),
      batterySocFilter(filterLabels),
      batteryChargingFilter(
        FLEET_READINESS_CHARGING_FILTER_OPTIONS,
        filterLabels,
        valueLabels
      ),
    ];
  }
};

export const getTiresFilterModalConfig = (
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels,
  hideAvailabilityFilter: boolean
): BDFilterModalOptionConfig<AssetListFilterCriteria>[] => {
  return [
    ...getCommonFilters(filterLabels, valueLabels, hideAvailabilityFilter),
    tireStatusFilter(filterLabels, valueLabels),
  ];
};

export const getAssetReadyConfig = (
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
): FilterOption<ReadyStatus>[] =>
  [ReadyStatus.READY, ReadyStatus.NOT_READY].map((status) => {
    return {
      label:
        valueLabels[
          `asset:filterModalLabels.readiness.${status}` as keyof typeof valueLabels
        ],
      value: status,
    };
  });

export const AVAILABLE_DIAGNOSTICS_HEALTH_OPTIONS = [
  ReportDiagnosticState.CRITICAL,
  ReportDiagnosticState.IMPAIRED,
];
export const getDiagnosticsHealthConfig = (
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
): FilterOption<ReportDiagnosticState>[] =>
  AVAILABLE_DIAGNOSTICS_HEALTH_OPTIONS.map((diagnosticStatus) => {
    return {
      label:
        valueLabels[
          `asset:diagnosticsHealth.${diagnosticStatus}` as keyof typeof valueLabels
        ],
      value: diagnosticStatus,
    };
  });

export const AVAILABLE_AVAILABILITY_OPTIONS = [
  AssetServiceStatus.IN_SERVICE,
  AssetServiceStatus.OUT_OF_SERVICE,
];
export const getAvailabilityConfig = (
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
): FilterOption<AssetServiceStatus>[] =>
  AVAILABLE_AVAILABILITY_OPTIONS.map((availabilityStatus) => {
    return {
      label: valueLabels[`asset:serviceStatus.${availabilityStatus}`],
      value: availabilityStatus,
    };
  });

export const getAssetFluidConfig = (
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
): FilterOption<ReportDiagnosticState>[] =>
  [ReportDiagnosticState.GOOD, ReportDiagnosticState.CRITICAL].map(
    (fluidStatus) => {
      return {
        label:
          valueLabels[
            `asset:filterModalLabels.fluidStatus.${fluidStatus}` as keyof typeof valueLabels
          ],
        value: fluidStatus,
      };
    }
  );
export const AVAILABLE_BATTERY_SOC_FILTER_OPTIONS =
  Object.values(BatterySocStatus);
export const getBatterySocConfig = (): FilterOption<BatterySocStatus>[] =>
  AVAILABLE_BATTERY_SOC_FILTER_OPTIONS.map((status) => {
    return {
      label: getRangeLabel(status),
      value: status,
    };
  });

export const AVAILABLE_LAST_COMMUNICATED_FILTER_OPTIONS = Object.values(
  LastCommunicatedStatus
);
export const getConnectivityConfig = (
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
): FilterOption<LastCommunicatedStatus>[] =>
  AVAILABLE_LAST_COMMUNICATED_FILTER_OPTIONS.map((status) => {
    return {
      label: valueLabels[`common:propertyState.${status}`],
      value: status,
    };
  });

export const FLEET_READINESS_CHARGING_FILTER_OPTIONS = [
  BatteryChargingStatus.CHARGING,
  BatteryChargingStatus.NOT_CHARGING,
];
export const BATTERY_READINESS_CHARGING_FILTER_OPTIONS = [
  BatteryChargingStatus.CHARGING,
  BatteryChargingStatus.INTERRUPTED,
  BatteryChargingStatus.COMPLETED,
  BatteryChargingStatus.NOT_PLUGGED_IN,
  BatteryChargingStatus.WAITING,
];

export const AVAILABLE_FUEL_FILTER_OPTIONS = Object.values(FuelStatus);
export const getFuelConfig = (): FilterOption<FuelStatus>[] =>
  AVAILABLE_FUEL_FILTER_OPTIONS.map((status) => {
    return {
      label: getDiagnosticModalLabel(FUEL_FILTER_MODAL_STATUS, status),
      value: status,
    };
  });

export const getChargingConfig = (
  options: BatteryChargingStatus[],
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
): FilterOption<BatteryChargingStatus>[] =>
  options.map((chargingStatus) => {
    return {
      label:
        valueLabels[
          `asset:filterModalLabels.chargingStatus.${chargingStatus}` as keyof typeof valueLabels
        ],
      value: chargingStatus,
    };
  });

export const AVAILABLE_TIRE_FILTER_OPTIONS = [
  ReportDiagnosticState.GOOD,
  ReportDiagnosticState.CRITICAL,
  ReportDiagnosticState.IMPAIRED,
];
export const getTireConfig = (
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
): FilterOption<ReportDiagnosticState>[] =>
  AVAILABLE_TIRE_FILTER_OPTIONS.map((tireStatus) => {
    return {
      label:
        valueLabels[
          `asset:filterModalLabels.tireStatus.${tireStatus}` as keyof typeof valueLabels
        ],
      value: tireStatus,
    };
  });

export const getSafetyComplianceConfig = (
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
): FilterOption<SafetyCompliance>[] =>
  [SafetyCompliance.COMPLIANT, SafetyCompliance.NON_COMPLIANT].map(
    (compliance) => {
      return {
        label:
          valueLabels[`asset:filterModalLabels.safetyCompliance.${compliance}`],
        value: compliance,
      };
    }
  );
export const AVAILABLE_FAULT_FILTER_OPTIONS = [
  ReportDiagnosticState.CRITICAL,
  ReportDiagnosticState.IMPAIRED,
];
export const getFaultConfig = (
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
): FilterOption<ReportDiagnosticState>[] =>
  AVAILABLE_FAULT_FILTER_OPTIONS.map((faultStatus) => {
    return {
      label:
        valueLabels[
          `asset:filterModalLabels.faultStatus.${faultStatus}` as keyof typeof valueLabels
        ],
      value: faultStatus,
    };
  });

export const AVAILABLE_POWER_TYPE_FILTER_OPTIONS = [PowerType.AC, PowerType.DC];
export const getPowerTypeFilterConfig = (
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels
): FilterOption<PowerType>[] =>
  AVAILABLE_POWER_TYPE_FILTER_OPTIONS.map((powerType) => {
    return {
      label:
        valueLabels[`asset:power.${powerType}` as keyof typeof valueLabels],
      value: powerType,
    };
  });
