import { cx } from '@emotion/css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactNode, useCallback, useState } from 'react';

import { FilterComponentProps, FilterOption } from './OptionFilter.model';
import useStyles from './OptionFilter.styles';

interface FilterProps<T> {
  id: string;
  label?: string;
  options?: FilterOption<T>[];
  icon?: ReactNode;
  selected?: T[];
  onSelectionChanged?: (selected: T[]) => void;
  displayComponent?: React.ComponentType<FilterComponentProps<T>>;
  valueAdapter?: (value: T) => string;
  disabledFilter?: boolean;
  dateStyleConfigs?: {
    dates: string[];
    style: SxProps<Theme>;
  }[];
}

const OptionFilter = <T,>({
  id,
  label,
  icon,
  options,
  selected,
  onSelectionChanged,
  displayComponent,
  valueAdapter,
  disabledFilter,
  dateStyleConfigs,
}: FilterProps<T>): JSX.Element => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const isDesktopSmallViewport = useMediaQuery(theme.breakpoints.down('lg'));

  const handleSelectionChanges = useCallback(
    (selection: T[]) => {
      onSelectionChanged && onSelectionChanged(selection);
    },
    [onSelectionChanged]
  );

  const updateValue = useCallback(
    (value: T | null) => {
      if (selected && value) {
        let index: number;
        if (valueAdapter) {
          index = selected.findIndex(
            (v) => valueAdapter(v) === valueAdapter(value)
          );
        } else {
          index = selected.findIndex((v) => v === value);
        }
        const updated =
          index > -1
            ? selected.filter((v) => v !== value)
            : [...selected, value];
        handleSelectionChanges(updated);
      } else if (value) {
        handleSelectionChanges([value]);
      } else {
        handleSelectionChanges([]);
      }
    },
    [handleSelectionChanges, selected, valueAdapter]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const DisplayComponent = displayComponent;

  return (
    <>
      {DisplayComponent && (
        <Box className={classes.container} data-testid={`${id}-filter`}>
          {label ? (
            <Button
              variant="text"
              onClick={handleClick}
              startIcon={icon}
              className={cx(classes.button, { selected: !!selected?.length })}
              disabled={disabledFilter}
              data-testid={`${id}-filter-button`}
            >
              {label}
              {!!selected?.length && <> ({selected.length})</>}
            </Button>
          ) : (
            <IconButton onClick={handleClick} disabled={disabledFilter}>
              {icon}
            </IconButton>
          )}
          <Popover
            id={id}
            style={{ backgroundColor: 'transparent' }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <DisplayComponent
              id={id}
              options={options}
              selected={selected}
              onUpdateValue={updateValue}
              onClose={handleClose}
              dateStyleConfigs={dateStyleConfigs}
            />
          </Popover>
        </Box>
      )}
    </>
  );
};

export default OptionFilter;
