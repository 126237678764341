import {
  AppConfig,
  BDEnvironment,
  loadAppConfig,
} from '@brightdrop/app-config';

export {};

declare global {
  interface Window {
    bd_config?: BDEnvironment;
    local_config?: BDEnvironment;
    __REACT_DEVTOOLS_GLOBAL_HOOK__: any;
  }

  // global augmentation requires use of var: https://github.com/Microsoft/TypeScript/pull/29332#issuecomment-785782937
  // eslint-disable-next-line no-var
  var appConfig: AppConfig;
}
globalThis.appConfig = loadAppConfig();

Object.freeze(globalThis.appConfig);
